// ViewNotesModal.js
import React, { useEffect, useState } from "react";
import { Modal, Button, message, Spin } from "antd";
import dayjs from "dayjs";
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axiosInstance from "./api/axiosConfig"; // Use the centralized axios instance


const ViewNotesModal = ({ isVisible, onClose, notes, onNotesUpdated }) => {
  const [sortedNotes, setSortedNotes] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (isVisible && Array.isArray(notes)) {
      setLoading(true);
      const sorted = [...notes].sort((a, b) => new Date(b.date) - new Date(a.date));
      setSortedNotes(sorted);
      setEditingNoteId(null);
      setEditedContent("");
      setHasUnsavedChanges(false);
      setLoading(false);
    } else {
      setSortedNotes([]);
    }
  }, [notes, isVisible]);

  const handleClose = () => {
    if (hasUnsavedChanges) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. Would you like to save before closing?',
        okText: 'Save & Close',
        cancelText: 'Discard Changes',
        onOk: async () => {
          if (editingNoteId) {
            await handleSave(editingNoteId);
          }
          onClose();
        },
        onCancel: () => {
          setEditingNoteId(null);
          setEditedContent("");
          setHasUnsavedChanges(false);
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  const formatDate = (dateString) => dayjs(dateString).format("dddd, MMMM D, YYYY");

  const handleDelete = async (noteId) => {
    try {
      setDeleteInProgress(true);
      await axiosInstance.delete(`/delete-general-note/${noteId}`);
      const updatedNotes = sortedNotes.filter((note) => note.id !== noteId);
      setSortedNotes(updatedNotes);
      if (onNotesUpdated) {
        onNotesUpdated();
      }
      message.success("Note deleted successfully.");
    } catch (error) {
      console.error("Error deleting note:", error);
      if (error.response?.status === 404) {
        message.warning("Note has already been deleted.");
        const updatedNotes = sortedNotes.filter((note) => note.id !== noteId);
        setSortedNotes(updatedNotes);
        if (onNotesUpdated) {
          onNotesUpdated();
        }
      } else {
        message.error("Failed to delete note.");
      }
    } finally {
      setDeleteInProgress(false);
    }
  };

  const startEditing = (note) => {
    setEditingNoteId(note.id);
    setEditedContent(note.notes);
    setHasUnsavedChanges(false); // Reset when starting to edit
  };

  const cancelEditing = () => {
    if (hasUnsavedChanges) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. Would you like to save before canceling?',
        okText: 'Save',
        cancelText: 'Discard Changes',
        onOk: async () => {
          if (editingNoteId) {
            await handleSave(editingNoteId);
          }
        },
        onCancel: () => {
          setEditingNoteId(null);
          setEditedContent("");
          setHasUnsavedChanges(false);
        },
      });
    } else {
      setEditingNoteId(null);
      setEditedContent("");
    }
  };

  const handleContentChange = (content) => {
    setEditedContent(content);
    setHasUnsavedChanges(true);
  };

  const handleSave = async (noteId) => {
    try {
      setSaveInProgress(true);
      const response = await axiosInstance.put(`/update-general-note/${noteId}`, {
        notes: editedContent,
      });

      if (response.data) {
        const updatedNotes = sortedNotes.map((note) =>
          note.id === noteId
            ? {
                ...note,
                notes: editedContent,
                updated_at: new Date().toISOString(),
              }
            : note
        );
        setSortedNotes(updatedNotes);
        setEditingNoteId(null);
        setEditedContent("");
        setHasUnsavedChanges(false);
        message.success("Note updated successfully.");
        if (onNotesUpdated) {
          onNotesUpdated();
        }
      }
    } catch (error) {
      console.error("Error updating note:", error);
      message.error("Failed to update note.");
    } finally {
      setSaveInProgress(false);
    }
  };

  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      ["link"],
    ],
  };

  return (
    <Modal
      title={
        <div style={{ textAlign: "left", fontSize: "18px", marginBottom: "15px" }}>
          General Notes
        </div>
      }
      open={isVisible}
      onCancel={handleClose}
      footer={null}
      width={800}
      bodyStyle={{ padding: "20px" }}
    >
      <div className="view-notes-content">
        {loading ? (
          <div style={{ textAlign: "center", padding: "50px 0" }}>
            <Spin tip="Loading..." />
          </div>
        ) : sortedNotes.length > 0 ? (
          sortedNotes.map((note) => (
            <div key={note.id} className="note-entry" style={{ marginBottom: "20px" }}>
              <div
                className="note-header"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <div className="note-info">
                  <div className="note-date">{formatDate(note.date)}</div>
                </div>
                <div className="note-actions">
                  {editingNoteId === note.id ? (
                    <>
                      <Button
                        type="link"
                        icon={<SaveOutlined />}
                        onClick={() => handleSave(note.id)}
                        loading={saveInProgress}
                      />
                      <Button
                        type="link"
                        icon={<CloseOutlined />}
                        onClick={cancelEditing}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => startEditing(note)}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(note.id)}
                        loading={deleteInProgress}
                      />
                    </>
                  )}
                </div>
              </div>
              {editingNoteId === note.id ? (
                <div className="note-editor" style={{ marginTop: "10px" }}>
                  <ReactQuill
                    value={editedContent}
                    onChange={handleContentChange}
                    modules={modules}
                  />
                </div>
              ) : (
                <div
                  className="note-text"
                  dangerouslySetInnerHTML={{ __html: note.notes }}
                  style={{ marginTop: "10px" }}
                />
              )}
            </div>
          ))
        ) : (
          <div className="no-notes-message" style={{ textAlign: "center", color: "#888" }}>
            No notes found.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewNotesModal;