import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { Layout, Typography, Row, Col } from 'antd';
import './styles.css';

const { Header, Content } = Layout;
const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();

  // Redirect to home page after successful sign-in
  const handleSignInComplete = () => {
    navigate('/');
  };

  return (
    <Layout className="layout">
      <Header className="header">
        <Title level={3} style={{ color: 'white', margin: 0 }}>
          Health Tracker
        </Title>
      </Header>
      <Content className="login-content">
        <Row justify="center" align="middle" style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Col xs={22} sm={16} md={12} lg={8} xl={6}>
            <SignIn 
              routing="path"
              path="/login"
              redirectUrl="/"
              appearance={{
                elements: {
                  formButtonPrimary: 'login-button',
                  rootBox: 'clerk-root-box',
                },
                layout: {
                  socialButtonsPlacement: 'bottom',
                  socialButtonsVariant: 'iconButton',
                  termsPageUrl: 'hidden',
                  privacyPageUrl: 'hidden',
                }
              }}
              afterSignInUrl="/"
            />
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <p>Contact your administrator for account access</p>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Login;
