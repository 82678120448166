import React from 'react';
import { Moon, CircleOff, Circle } from 'lucide-react';

// Function to map pollen levels to color codes
const getPollenLevelColor = (level) => {
    switch (level) {
        case 'Very High':
            return '#0a5d00';  // Dark green
        case 'High':
            return '#1a8d00';  // Medium-dark green
        case 'Medium':
            return '#90EE90';  // Light green
        case 'Low':
            return '#e8ffe8';  // Very light green
        case 'Negligible':
            return 'transparent';  // No color
        default:
            return 'transparent';
    }
};

// Function to generate moon phase icons
const getMoonIcon = (phase) => {
    const iconProps = {
        size: 24,
        color: '#1a9cd4',
        className: 'moon-phase-icon',
        strokeWidth: 1.5
    };

    switch (phase) {
        case 'New Moon':
            return <CircleOff {...iconProps} />;
        case 'Waxing Crescent':
            return <Moon {...iconProps} fill="transparent" />;
        case 'First Quarter':
            return <Moon {...iconProps} fill="#1a9cd4" transform="scale(-1,1)" />;
        case 'Waxing Gibbous':
            return <Moon {...iconProps} fill="#1a9cd4" />;
        case 'Full Moon':
            return <Circle {...iconProps} fill="#1a9cd4" />;
        case 'Waning Gibbous':
            return <Moon {...iconProps} fill="#1a9cd4" transform="rotate(180)" />;
        case 'Last Quarter':
            return <Moon {...iconProps} fill="#1a9cd4" />;
        case 'Waning Crescent':
            return <Moon {...iconProps} fill="transparent" transform="rotate(180)" />;
        default:
            return <Moon {...iconProps} />;
    }
};

const WeatherInfo = ({ weatherData }) => {
    if (!weatherData) return <div>No weather data available for this date.</div>;

    console.log("WeatherInfo received data:", weatherData);
    console.log("Pollen level:", weatherData.pollenLevel);

    // Format the time strings to ensure consistent display
    const formatTimeString = (timeStr) => {
        if (!timeStr) return '';
        // Ensure proper formatting of AM/PM
        return timeStr.replace(/([AaPp][Mm])/, ' $1').replace(/\s+/g, ' ').trim();
    };

    return (
        <div className="weather-info">
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '5px' }}>
                <div className="weather-item">
                    <span className="weather-label">Temperature:</span>
                    <span className="weather-value">{weatherData.temperature}°F</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Weather:</span>
                    <span className="weather-value">{weatherData.description}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Pollen Level:</span>
                    <span className="weather-value">
                        <span
                            className="pollen-indicator"
                            style={{
                                backgroundColor: getPollenLevelColor(weatherData.pollenLevel)
                            }}
                        />
                        {weatherData.pollenLevel}
                    </span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Sunrise:</span>
                    <span className="weather-value">{formatTimeString(weatherData.sunrise)}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Sunset:</span>
                    <span className="weather-value">{formatTimeString(weatherData.sunset)}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Daylight:</span>
                    <span className="weather-value">{weatherData.hoursOfDaylight}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Moon Phase:</span>
                    <span className="weather-value">
                        {getMoonIcon(weatherData.moonPhase)}
                        <span>{weatherData.moonPhase}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WeatherInfo;