import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import MainPage from "./MainPage";
import ViewData from "./ViewData";
import PrintView from "./PrintView";
import ViewGraph from "./ViewGraph";
import Login from "./Login";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "./AuthContext";

// Get your Clerk publishable key from environment variables
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const clerkJsUrl = process.env.REACT_APP_CLERK_JS_URL;

// Navigation configuration for Clerk
const clerkNavigationConfig = {
  // Specify routes for different authentication flows
  signInUrl: '/login',
  afterSignInUrl: '/',
};

function App() {
  return (
    <ClerkProvider 
      publishableKey={clerkPubKey}
      navigate={(to) => window.location.href = to}
      {...clerkNavigationConfig}
      jsUrl={clerkJsUrl}
    >
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/login/*" element={<Login />} />
            
            {/* Protected routes */}
            <Route 
              path="/" 
              element={
                <ProtectedRoute>
                  <MainPage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/view-graph" 
              element={
                <ProtectedRoute>
                  <ViewGraph />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/view-data" 
              element={
                <ProtectedRoute>
                  <ViewData />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/print/:startDate/:endDate" 
              element={
                <ProtectedRoute>
                  <PrintView />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ClerkProvider>
  );
}

export default App;