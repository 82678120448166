import React, { createContext, useState, useEffect, useCallback } from "react";
import { useAuth, useUser, useClerk } from "@clerk/clerk-react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isLoaded, userId, sessionId, getToken } = useAuth();
  const { user } = useUser();
  const clerk = useClerk();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      setIsAuthenticated(!!userId);
      setIsLoading(false);
    }
  }, [isLoaded, userId]);

  // Get auth token for API requests
  const getAuthToken = useCallback(async () => {
    if (!userId) return null;
    
    try {
      const token = await getToken();
      setAuthToken(token);
      return token;
    } catch (error) {
      console.error("Error getting auth token:", error);
      return null;
    }
  }, [userId, getToken]);

  useEffect(() => {
    if (userId) {
      getAuthToken();
    }
  }, [userId, getAuthToken]);

  const logout = useCallback(async () => {
    try {
      await clerk.signOut({ 
        redirectUrl: '/' 
      });
      // The following will be handled by Clerk's hooks automatically
      setAuthToken(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }, [clerk]);

  const navigateToUserProfile = useCallback(() => {
    clerk.openUserProfile();
  }, [clerk]);

  return (
    <AuthContext.Provider
      value={{ 
        isAuthenticated, 
        user, 
        logout, 
        isLoading, 
        userId, 
        sessionId,
        authToken,
        getAuthToken,
        navigateToUserProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
