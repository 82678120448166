import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

// Import shared axios instance and auth context
import axiosInstance from "./api/axiosConfig";
import { AuthContext } from "./AuthContext";

function ViewData() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated, authToken, getAuthToken } = useContext(AuthContext);

  // Enhanced fetchData function with comprehensive debugging
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    
    // Get fresh auth token and log authentication state
    const token = await getAuthToken();
    console.log('ViewData: Authentication state:', { 
      isAuthenticated, 
      hasToken: !!token,
      tokenLength: token ? token.length : 0
    });
    
    // Log request headers before making the call
    const currentHeaders = { ...axiosInstance.defaults.headers.common };
    console.log('ViewData: Current axios headers:', currentHeaders);
    
    try {
      console.log('ViewData: Fetching data from /view-all-data');
      
      // Make sure auth header is set (in case there was an issue with interceptors)
      if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        console.log('ViewData: Manually set Authorization header');
      }
      
      // Make the request with explicit URL to troubleshoot proxy issues
      const fullUrl = window.location.origin + '/view-all-data';
      console.log('ViewData: Full request URL:', fullUrl);
      
      const response = await axiosInstance.get('/view-all-data');
      console.log('ViewData: Fetched data successfully:', response.data);
      const sortedData = sortData(response.data);
      console.log('ViewData: Sorted data:', sortedData);
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error('ViewData: Error fetching data:', error);
      setError('Failed to load data');
      setLoading(false);
      
      // Enhanced error logging for debugging
      if (error.response) {
        console.error('ViewData: Server responded with error:', {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers
        });
      } else if (error.request) {
        console.error('ViewData: No response received from server', {
          requestDetails: error.request
        });
      } else {
        console.error('ViewData: Error setting up request:', error.message);
      }
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    // Only attempt to fetch if authenticated
    if (isAuthenticated) {
      fetchData();
    } else {
      console.log('ViewData: Not authenticated yet, waiting...');
    }
  }, [isAuthenticated]); // Re-run when authentication state changes

  const sortData = (data) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;

      if (a.section < b.section) return -1;
      if (a.section > b.section) return 1;

      if (a.field < b.field) return -1;
      if (a.field > b.field) return 1;

      return 0;
    });
  };

  return (
    <div className="container">
      <h2>View Data</h2>
      {loading ? (
        <p>Loading data...</p>
      ) : error ? (
        <div>
          <p>Error: {error}</p>
          <button onClick={fetchData}>Retry</button>
        </div>
      ) : data.length === 0 ? (
        <p>No data available.</p>
      ) : (
        <table className="data-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Section</th>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>{item.section}</td>
                <td>{item.field}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <footer className="data-footer">
        <button className="home-button" onClick={() => navigate("/")}>
          Home
        </button>
      </footer>
    </div>
  );
}

export default ViewData;