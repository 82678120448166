import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { PlusOutlined, MenuOutlined, DeleteOutlined } from '@ant-design/icons';

const MedicationValuesModal = ({ isVisible, onClose, onSave, currentMedications }) => {
  console.log('[DEBUG-MODAL] MedicationValuesModal rendered with props:', { isVisible, currentMedications });
  
  // Initialize with currentMedications or fallback to localStorage or empty array
  const [medications, setMedications] = useState(() => {
    if (currentMedications && Array.isArray(currentMedications) && currentMedications.length > 0) {
      console.log('[DEBUG-MODAL] Initializing with currentMedications prop:', currentMedications);
      return [...currentMedications];
    }
    
    // Try to get medications from localStorage as fallback
    try {
      const storedMedications = localStorage.getItem('medicationOptions');
      if (storedMedications) {
        const parsedMedications = JSON.parse(storedMedications);
        if (Array.isArray(parsedMedications) && parsedMedications.length > 0) {
          console.log('[DEBUG-MODAL] Initializing with medications from localStorage:', parsedMedications);
          return parsedMedications;
        }
      }
    } catch (error) {
      console.error('[DEBUG-MODAL] Error reading from localStorage:', error);
    }
    
    console.log('[DEBUG-MODAL] Initializing with empty array');
    return [];
  });
  
  const [newMedication, setNewMedication] = useState('');
  const [draggedIndex, setDraggedIndex] = useState(null);
  
  // Effect to update medications state when currentMedications prop changes
  useEffect(() => {
    console.log('[DEBUG-MODAL] currentMedications prop changed:', currentMedications);
    console.log('[DEBUG-MODAL] Current medications state:', medications);
    
    // Compare the arrays to see if they're different
    const isDifferent = () => {
      if (!currentMedications || !Array.isArray(currentMedications)) return false;
      if (medications.length !== currentMedications.length) return true;
      
      // Check if any elements are different
      for (let i = 0; i < currentMedications.length; i++) {
        if (medications[i] !== currentMedications[i]) return true;
      }
      return false;
    };
    
    if (currentMedications && Array.isArray(currentMedications) && currentMedications.length > 0 && isDifferent()) {
      console.log('[DEBUG-MODAL] Updating medications state with new currentMedications');
      setMedications([...currentMedications]); // Create a new array to ensure state update
      
      // Also update localStorage to ensure consistency
      try {
        localStorage.setItem('medicationOptions', JSON.stringify(currentMedications));
        console.log('[DEBUG-MODAL] Updated localStorage with currentMedications');
      } catch (error) {
        console.error('[DEBUG-MODAL] Error saving to localStorage:', error);
      }
    } else if (!currentMedications || !Array.isArray(currentMedications) || currentMedications.length === 0) {
      console.log('[DEBUG-MODAL] currentMedications is invalid or empty:', currentMedications);
      // Don't reset medications if currentMedications is empty - keep what we have
    } else {
      console.log('[DEBUG-MODAL] currentMedications matches current state - no update needed');
    }
  }, [currentMedications]); // Removed medications from dependency array to prevent infinite loop

  const handleAddMedication = () => {
    const trimmedMedication = newMedication.trim();
    
    if (!trimmedMedication) {
      console.log('[DEBUG-MODAL] Cannot add empty medication');
      message.warning('Medication name cannot be empty');
      return;
    }
    
    // Case-insensitive check for duplicates
    const isDuplicate = medications.some(
      med => med.toLowerCase() === trimmedMedication.toLowerCase()
    );
    
    if (isDuplicate) {
      console.log('[DEBUG-MODAL] Cannot add duplicate medication:', trimmedMedication);
      message.warning('This medication already exists in the list');
      return;
    }
    
    console.log('[DEBUG-MODAL] Adding new medication:', trimmedMedication);
    const updatedMedications = [...medications, trimmedMedication];
    console.log('[DEBUG-MODAL] Updated medications list:', updatedMedications);
    
    // Update state and localStorage
    setMedications(updatedMedications);
    try {
      localStorage.setItem('medicationOptions', JSON.stringify(updatedMedications));
      console.log('[DEBUG-MODAL] Updated localStorage with new medications');
    } catch (error) {
      console.error('[DEBUG-MODAL] Error saving to localStorage:', error);
    }
    
    setNewMedication('');
  };

  const handleDeleteMedication = (index) => {
    console.log('[DEBUG-MODAL] Deleting medication at index:', index);
    const newMedications = medications.filter((_, i) => i !== index);
    console.log('[DEBUG-MODAL] Updated medications after deletion:', newMedications);
    
    // Update state and localStorage
    setMedications(newMedications);
    try {
      localStorage.setItem('medicationOptions', JSON.stringify(newMedications));
      console.log('[DEBUG-MODAL] Updated localStorage after deletion');
    } catch (error) {
      console.error('[DEBUG-MODAL] Error saving to localStorage after deletion:', error);
    }
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === index) return;

    const newMedications = [...medications];
    const draggedItem = newMedications[draggedIndex];
    newMedications.splice(draggedIndex, 1);
    newMedications.splice(index, 0, draggedItem);
    
    // Update state and localStorage
    setMedications(newMedications);
    try {
      localStorage.setItem('medicationOptions', JSON.stringify(newMedications));
      console.log('[DEBUG-MODAL] Updated localStorage after reordering');
    } catch (error) {
      console.error('[DEBUG-MODAL] Error saving to localStorage after reordering:', error);
    }
    
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleSave = () => {
    console.log('[DEBUG-MODAL] Saving medications:', medications);
    
    // Final localStorage update before saving to server
    try {
      localStorage.setItem('medicationOptions', JSON.stringify(medications));
      console.log('[DEBUG-MODAL] Final localStorage update before saving to server');
    } catch (error) {
      console.error('[DEBUG-MODAL] Error with final localStorage update:', error);
    }
    
    // Call parent component's onSave function
    onSave(medications);
    
    // Show success message and close modal
    message.success('Medication list saved successfully');
    onClose();
  };

  return (
    <Modal
      title="Edit Medication List"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>
      ]}
    >
      <div className="add-medication-section">
        <Input
          value={newMedication}
          onChange={(e) => setNewMedication(e.target.value)}
          onPressEnter={handleAddMedication}
          placeholder="Enter new medication"
          style={{ marginRight: '8px' }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddMedication}
        >
          Add
        </Button>
      </div>

      <div className="medications-list">
        {medications.map((medication, index) => (
          <div
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
            className={`medication-item ${
              draggedIndex === index ? 'dragging' : ''
            }`}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px',
              marginBottom: '4px',
              cursor: 'move'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MenuOutlined style={{ marginRight: '8px', color: '#999' }} />
              {medication}
            </div>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteMedication(index)}
              danger
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default MedicationValuesModal;