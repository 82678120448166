import React, { useState, useEffect } from "react";
import { Modal, Button, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const GeneralNotesModal = ({
  isVisible,
  onClose,
  onSave,
  defaultValue = '',
  value,
  onChange,
}) => {
  const [localNotes, setLocalNotes] = useState(value || defaultValue);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setLocalNotes(value || defaultValue);
      setHasUnsavedChanges(false);
    }
  }, [isVisible, value, defaultValue]);

  const handleSave = () => {
    if (!localNotes.trim()) {
      message.warning('Please enter some notes before saving');
      return;
    }
    onSave(localNotes);
    setHasUnsavedChanges(false);
  };

  const handleClose = () => {
    if (hasUnsavedChanges) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. Are you sure you want to close?',
        onOk: () => {
          setLocalNotes(value || defaultValue);
          setHasUnsavedChanges(false);
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
      ],
    },
  };

  return (
    <Modal
      title={
        <div
          style={{ textAlign: "left", fontSize: "18px", marginBottom: "15px" }}
        >
          General Notes
        </div>
      }
      open={isVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      width={800}
      bodyStyle={{ padding: "20px", paddingTop: "5px", paddingBottom: "40px" }}
    >
      <div className="general-notes-content">
        <ReactQuill
          value={localNotes}
          onChange={(content) => {
            setLocalNotes(content);
            if (onChange) {
              onChange(content);
            }
            setHasUnsavedChanges(true);
          }}
          modules={modules}
          style={{
            height: "300px",
            marginBottom: "0",
          }}
        />
      </div>
    </Modal>
  );
};

export default GeneralNotesModal;
