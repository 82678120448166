import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./api/axiosConfig";
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartTooltip,
  ResponsiveContainer, Legend, Label
} from "recharts";
import {
  Button,
  DatePicker,
  Select,
  Spin,
  message,
  Divider,
  Card,
  Empty,
  Tooltip
} from "antd";
import { 
  HomeOutlined, 
  CalendarOutlined, 
  LineChartOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import "./styles.css";

const { RangePicker } = DatePicker;
const { Option } = Select;

function ViewGraph() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [datapoints, setDatapoints] = useState([]);
  const [selectedDatapoint, setSelectedDatapoint] = useState('mood'); // Set default datapoint to mood
  const [selectedSubfield, setSelectedSubfield] = useState(null);
  const [dateRange, setDateRange] = useState([dayjs().subtract(7, 'day'), dayjs()]);
  const [graphData, setGraphData] = useState([]);
  const [graphConfig, setGraphConfig] = useState({
    yAxisLabel: '',
    tooltip: '',
    dataKeys: [],
    colors: [],
    multipleMetrics: false
  });

  // Available datapoints for selection
  const availableDatapoints = [
    { label: 'Mood', value: 'mood', field: 'feeling', color: '#f5222d' },
    { label: 'Exercise', value: 'exercise', fields: [
      { name: 'ruckWeight', label: 'Ruck Weight', color: '#1890ff' },
      { name: 'walkingMiles', label: 'Walking Miles', color: '#52c41a' }
    ]},
    { label: 'Diet Cokes', value: 'dietCoke', field: 'dietCoke', color: '#722ed1' },
    { label: 'Milks', value: 'milk', field: 'milk', color: '#faad14' },
    { label: 'Daylight', value: 'daylight', field: 'daylight', color: '#13c2c2' }
  ];

  // Fetch data for selected datapoint and date range
  const fetchData = useCallback(async () => {
    if (!selectedDatapoint || !dateRange || !dateRange[0] || !dateRange[1]) {
      return;
    }

    const startDate = dateRange[0].format('YYYY-MM-DD');
    const endDate = dateRange[1].format('YYYY-MM-DD');
    const datapoint = availableDatapoints.find(dp => dp.value === selectedDatapoint);

    if (!datapoint) {
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.get(`/weekly-metrics-summary/${startDate}/${endDate}`);
      
      // Process the response data based on the selected datapoint
      let processedData = [];
      let newGraphConfig = {};
      
      if (selectedDatapoint === 'mood') {
        // Process mood data - extract feelings
        if (response.data.mood && response.data.mood.dailyMood) {
          processedData = Object.entries(response.data.mood.dailyMood).map(([date, moods]) => {
            // Calculate average feeling for each day
            const feelings = Array.isArray(moods) ? moods.map(m => parseFloat(m) || 0) : [];
            const avgFeeling = feelings.length > 0 
              ? feelings.reduce((sum, f) => sum + f, 0) / feelings.length 
              : 0;
              
            return {
              date: dayjs(date).format('MMM DD'),
              originalDate: date,
              feeling: Number(avgFeeling.toFixed(1)),
              tooltipFeeling: `Average: ${avgFeeling.toFixed(1)}`
            };
          });
          
          // Single datapoint config
          newGraphConfig = {
            yAxisLabel: 'Mood Level',
            tooltip: 'Mood',
            dataKeys: ['feeling'],
            colors: ['#f5222d'],
            multipleMetrics: false
          };
        }
      } else if (selectedDatapoint === 'exercise') {
        // Process exercise data to show both metrics
        if (response.data.exercise && response.data.exercise.daily) {
          processedData = Object.entries(response.data.exercise.daily).map(([date, exercise]) => {
            return {
              date: dayjs(date).format('MMM DD'),
              originalDate: date,
              walkingMiles: parseFloat(exercise.walkingMiles || 0),
              ruckWeight: parseFloat(exercise.ruckWeight || 0),
              tooltipWalkingMiles: `${exercise.walkingMiles || 0} miles`,
              tooltipRuckWeight: `${exercise.ruckWeight || 0} lbs`
            };
          });
          
          // Multiple datapoint config
          newGraphConfig = {
            yAxisLabel: 'Exercise Metrics',
            tooltip: 'Exercise',
            dataKeys: ['ruckWeight', 'walkingMiles'],
            colors: ['#1890ff', '#52c41a'],
            multipleMetrics: true
          };
        }
      } else if (selectedDatapoint === 'dietCoke' || selectedDatapoint === 'milk') {
        const datapoint = availableDatapoints.find(dp => dp.value === selectedDatapoint);
        
        if (response.data.beverages) {
          const field = datapoint.field;
          const dailyData = field === 'dietCoke' ? response.data.beverages.dailyDietCoke : response.data.beverages.dailyMilk;
          
          if (dailyData) {
            // Filter out the incorrect milk data for March 11
            // This is a client-side solution to handle data inconsistencies
            // without needing to modify the database or server-side code
            // Only applied to milk data to preserve all other functionality
            const filteredData = field === 'milk' ? 
              Object.fromEntries(Object.entries(dailyData).filter(([date]) => date !== '2025-03-11')) : 
              dailyData;
            
            processedData = Object.entries(filteredData).map(([date, count]) => {
              return {
                date: dayjs(date).format('MMM DD'),
                originalDate: date,
                [field]: parseInt(count || 0),
                [`tooltip${field.charAt(0).toUpperCase() + field.slice(1)}`]: `${count || 0} ${count === 1 ? field === 'dietCoke' ? 'can' : 'glass' : field === 'dietCoke' ? 'cans' : 'glasses'}`
              };
            });
            
            // Single datapoint config for beverages
            newGraphConfig = {
              yAxisLabel: field === 'dietCoke' ? 'Number of Diet Cokes' : 'Number of Milks',
              tooltip: field === 'dietCoke' ? 'Diet Cokes' : 'Milks',
              dataKeys: [field],
              colors: [datapoint.color],
              multipleMetrics: false,
              // Custom Y-axis tick marks for beverage data, starting at 0 with 0.5 increments
              // This improves data visualization by ensuring consistent scale and half-point precision
              // Configured specifically for beverage metrics to maintain existing functionality elsewhere
              yAxisTicks: [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6]
            };
          }
        }
      } else if (selectedDatapoint === 'daylight') {
        const datapoint = availableDatapoints.find(dp => dp.value === selectedDatapoint);
        
        if (response.data.weather && response.data.weather.dailyDaylight) {
          const dailyData = response.data.weather.dailyDaylight;
          
          if (dailyData) {
            // Filter dailyData to only include dates within the selected range
            const startDate = dayjs(dateRange[0]).format('YYYY-MM-DD');
            const endDate = dayjs(dateRange[1]).format('YYYY-MM-DD');
            
            processedData = Object.entries(dailyData)
              .filter(([date]) => {
                // Only include dates within the selected range
                return date >= startDate && date <= endDate;
              })
              .map(([date, hours]) => {
                // Convert hours string (e.g., "12h 45m") to minutes for graphing
                const hoursMatch = hours.match(/(\d+)h/);
                const minutesMatch = hours.match(/(\d+)m/);
                const hoursValue = hoursMatch ? parseInt(hoursMatch[1]) : 0;
                const minutesValue = minutesMatch ? parseInt(minutesMatch[1]) : 0;
                const totalHoursDecimal = hoursValue + (minutesValue / 60);
                
                return {
                  date: dayjs(date).format('MMM DD'),
                  originalDate: date,
                  daylight: parseFloat(totalHoursDecimal.toFixed(2)),
                  tooltipDaylight: hours // Original formatted string (e.g., "12h 45m")
                };
              });
            
            // Daylight datapoint config
            newGraphConfig = {
              yAxisLabel: 'Hours of Daylight',
              tooltip: 'Daylight',
              dataKeys: ['daylight'],
              colors: [datapoint.color],
              multipleMetrics: false
            };
          }
        }
      }

      // Sort by date
      processedData.sort((a, b) => new Date(a.originalDate) - new Date(b.originalDate));
      
      setGraphData(processedData);
      setGraphConfig(newGraphConfig);

    } catch (error) {
      console.error('Error fetching graph data:', error);
      message.error('Failed to fetch data for the selected date range');
    } finally {
      setLoading(false);
    }
  }, [selectedDatapoint, dateRange]);

  // Fetch data when datapoint or date range changes
  useEffect(() => {
    if (selectedDatapoint && dateRange && dateRange[0] && dateRange[1]) {
      console.log('Fetching data for:', selectedDatapoint, dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
      fetchData();
    }
  }, [fetchData, selectedDatapoint, dateRange]);
  
  // Initial data load
  useEffect(() => {
    // Set default selections and fetch data automatically on component mount
    if (selectedDatapoint && dateRange && dateRange[0] && dateRange[1]) {
      console.log('Initial data load for:', selectedDatapoint);
      fetchData();
    }
  }, []);

  // Navigate back to main page
  const handleBackToMain = () => {
    navigate("/");
  };

  // Handle datapoint selection change
  const handleDatapointChange = (value) => {
    setSelectedDatapoint(value);
  };

  // Handle date range selection
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  // Custom tooltip formatter for the graph
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="tooltip-date">{label}</p>
          {payload.map((entry, index) => {
            const dataKey = entry.dataKey;
            const tooltipKey = `tooltip${dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}`;
            const displayName = dataKey === 'walkingMiles' ? 'Walking Miles' : 
                               dataKey === 'ruckWeight' ? 'Ruck Weight' : 
                               dataKey === 'feeling' ? 'Mood' : 
                               dataKey === 'dietCoke' ? 'Diet Cokes' :
                               dataKey === 'milk' ? 'Milks' :
                               dataKey === 'daylight' ? 'Daylight' : entry.name;
            
            return (
              <p key={index} className="tooltip-value" style={{ color: entry.color }}>
                {displayName}: {entry.payload[tooltipKey] || entry.value}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="container">
      <h1>
        <LineChartOutlined /> View Graph
      </h1>
      <Card className="graph-card">
        <div className="graph-controls">
          <div className="control-group">
            <label htmlFor="datapoint-select">Select Data Point:</label>
            <Select
              id="datapoint-select"
              placeholder="Select data point"
              style={{ width: '100%' }}
              onChange={handleDatapointChange}
              value={selectedDatapoint}
            >
              {availableDatapoints.map((datapoint) => (
                <Option key={datapoint.value} value={datapoint.value}>
                  {datapoint.label}
                </Option>
              ))}
            </Select>
          </div>
          
          <div className="control-group">
            <label htmlFor="date-range-picker">
              Select Date Range:
              <Tooltip title="Data will be shown for the selected date range">
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </label>
            <RangePicker
              id="date-range-picker"
              value={dateRange}
              onChange={handleDateRangeChange}
              format="YYYY-MM-DD"
              allowClear={false}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        
        <Divider />
        
        {loading ? (
          <div className="graph-loading">
            <Spin size="large" />
            <p>Loading data...</p>
          </div>
        ) : graphData.length > 0 ? (
          <div className="graph-container">
            <ResponsiveContainer width="100%" height={350}>
              <LineChart
                data={graphData}
                margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis 
                  dataKey="date" 
                  tick={{ fontSize: 12 }}
                  padding={{ left: 10, right: 10 }}
                >
                  <Label value="Date" position="bottom" style={{ textAnchor: 'middle', fontSize: 14 }} />
                </XAxis>
                <YAxis 
                  tick={{ fontSize: 12 }}
                  // Set Y-axis to always start at 0 for better data visualization
                  // The upper bound remains automatic to accommodate different data ranges
                  domain={[0, 'auto']}
                  // Apply custom tick configuration if specified in the graphConfig
                  // This allows for datapoint-specific tick marks while preserving default behavior for other metrics
                  ticks={graphConfig.yAxisTicks}
                >
                  <Label 
                    value={graphConfig.yAxisLabel} 
                    angle={-90} 
                    position="left" 
                    style={{ textAnchor: 'middle', fontSize: 14 }} 
                  />
                </YAxis>
                <RechartTooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" height={36} />
                {graphConfig.dataKeys.map((dataKey, index) => (
                  <Line
                    key={dataKey}
                    type="monotone"
                    dataKey={dataKey}
                    name={selectedDatapoint === 'exercise' ? 
                      (dataKey === 'walkingMiles' ? 'Walking Miles' : 'Ruck Weight') : 
                      graphConfig.tooltip}
                    stroke={graphConfig.colors[index]}
                    activeDot={{ r: 8 }}
                    strokeWidth={2}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : selectedDatapoint ? (
          <Empty description="No data available for the selected period" />
        ) : (
          <Empty description="Select a data point and date range to view the graph" />
        )}
      </Card>
      
      <footer className="footer">
        <Button 
          type="primary" 
          onClick={handleBackToMain} 
          icon={<HomeOutlined />}
        >
          Back to Main Page
        </Button>
      </footer>
    </div>
  );
}

export default ViewGraph;
