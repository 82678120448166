import React, { useState, useEffect } from "react";
import { Modal, DatePicker, Button } from "antd";
import dayjs from "dayjs";

const MetricsSummaryModal = ({ isVisible, onClose, fetchData, summaryData, dateRange }) => {
  console.log("MetricsSummaryModal received data:", summaryData);
  if (!summaryData) {
    return null;
  }

  const [selectedDateRange, setSelectedDateRange] = useState([
    dayjs().subtract(7, 'days'),
    dayjs()
  ]);

  useEffect(() => {
    if (dateRange) {
      const [startStr, endStr] = dateRange.split(' - ');
      if (startStr && endStr) {
        setSelectedDateRange([
          dayjs(startStr),
          dayjs(endStr)
        ]);
      }
    }
  }, [dateRange]);

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleFetchData = () => {
    fetchData(selectedDateRange);
  };

  return (
    <Modal
      title={
        <div>
          <h2 style={{ margin: 0, fontWeight: "bold" }}>Metrics Summary</h2>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              margin: "5px 0 15px 0",
            }}
          >
            for {selectedDateRange[0].format("MMM D, YYYY")} - {selectedDateRange[1].format("MMM D, YYYY")}
          </p>
        </div>
      }
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button key="ok" onClick={onClose} className="modal-button">
          OK
        </Button>,
      ]}
    >
      <div className="weekly-summary">
        <div className="summary-section">
          <h3 style={{ fontSize: "18px", color: "#1a9cd4" }}>Sleep</h3>
          
          <p><strong>Total Sleep Hours:</strong> {summaryData.sleep?.totalHours || "N/A"}</p>
          <p><strong>Average Sleep Hours per Night:</strong> {summaryData.sleep?.averageHours || "N/A"}</p>
          <p><strong>Average Arise Time:</strong> {summaryData.sleep?.averageAriseTime || "N/A"}</p>
          <p><strong>Average Bedtime:</strong> {summaryData.sleep?.averageBedtime || "N/A"}</p>
          
          {summaryData.sleep?.totalHours.includes("*") && (
            <p>
              <small>
                * Some days had incomplete sleep data and were not included in
                the calculation.
              </small>
            </p>
          )}
        </div>

        <div className="summary-section">
          <h3 style={{ fontSize: "18px", color: "#1a9cd4" }}>
            Beverages & Food
          </h3>
          
          <p><strong>Total Number of Diet Cokes:</strong> {summaryData.beverages?.totalDietCokes || "N/A"}</p>
          <p><strong>Average Diet Cokes Per Day:</strong> {summaryData.beverages?.avgDietCokesPerDay || "N/A"}</p>
          <p><strong>Total Number of Milks:</strong> {summaryData.beverages?.totalMilks || "N/A"}</p>
          <p><strong>Average Milks Per Day:</strong> {summaryData.beverages?.avgMilksPerDay || "N/A"}</p>
          <p><strong>Cheat Day?</strong> {summaryData.beverages?.hasCheatDay ? "Yes" : "No"}</p>
        </div>

        <div className="summary-section">
          <h3 style={{ fontSize: "18px", color: "#1a9cd4" }}>Exercise</h3>
          
          <p><strong>Total Walking Miles:</strong> {summaryData.exercise?.totalWalkingMiles || "N/A"}</p>
          <p><strong>Total Rucking Days:</strong> {summaryData.exercise?.totalRuckingDays || "N/A"}</p>
          <p><strong>Total Resistance Days:</strong> {summaryData.exercise?.totalResistanceDays || "N/A"}</p>
        </div>
        
        <div className="summary-section">
          <h3 style={{ fontSize: "18px", color: "#1a9cd4" }}>Mood</h3>
          
          <p><strong>Total Flags:</strong> {summaryData.mood?.totalFlags || "N/A"}</p>
          <p><strong>Total Red Zone Days:</strong> {summaryData.mood?.totalRedZone || "N/A"}</p>
        </div>
        <hr style={{ width: "100%", marginBottom: "20px" }} />
        <div className="summary-section" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: "20px" }}>
          <h3 style={{ fontSize: "18px", color: "#1a9cd4" }}>Custom Date Range</h3>
          <DatePicker.RangePicker
            value={selectedDateRange}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            style={{ width: "100%", maxWidth: "300px", marginBottom: "10px" }}
          />
          <Button type="primary" onClick={handleFetchData} style={{ width: "100%", maxWidth: "300px" }}>
            Fetch Data
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MetricsSummaryModal;
