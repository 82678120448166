// Imports
import React, { useState, useEffect, useCallback, useContext } from "react";
import axiosInstance from "./api/axiosConfig";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import WeatherInfo from "./WeatherInfo";
import jsPDF from "jspdf";
import { 
  Button, 
  message, 
  Tooltip, 
  Spin, 
  Popconfirm,
  DatePicker,
  Divider,
  Modal,
  Tag,
  Input,
  Select,
  Dropdown,
} from "antd";
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  PrinterOutlined,
  AreaChartOutlined,
  DatabaseOutlined,
  BarChartOutlined,
  PlusOutlined,
  FileTextOutlined,
  EyeOutlined,
  EditOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import WeeklyMetricsSummaryModal from "./WeeklyMetricsSummaryModal"; // Renamed import
import GeneralNotesModal from "./GeneralNotesModal";
import ViewNotesModal from "./ViewNotesModal";
import MedicationValuesModal from './MedicationValuesModal';
import { AuthContext } from "./AuthContext";

console.log("Starting MainPage component initialization");

const { RangePicker } = DatePicker;
const { Option } = Select;

// insertTime Function
const insertTime = function () {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const currentTime = formattedHours + ':' + formattedMinutes + ' ' + ampm;
/*   const currentTime = `${formattedHours}:${formattedMinutes} ${ampm}`; */

  const editor = this.quill;
  const range = editor.getSelection();
  if (range) {
    // Insert a new line if we're not at the start of the document
    if (range.index > 0) {
      editor.insertText(range.index, "\n");
    }
    // Insert bullet point and time without indentation
    editor.insertText(range.index + 1, "• " + currentTime + " ");
    // Move cursor to the end of the inserted text
    editor.setSelection(range.index + currentTime.length + 3); // +3 for the bullet and spaces
  }
};

// Save tag function
const saveTag = async (tag) => {
  // Validate the input tag before attempting to save it
  if (!tag || typeof tag.text !== 'string' || typeof tag.category !== 'string' || tag.text.trim() === '') {
    console.error("Invalid tag data:", tag);
    message.error("Cannot save an empty or invalid tag.");
    return null; // Return null to indicate failure
  }

  try {
    // Prepare the tag object for the API call
    const tagPayload = {
      text: tag.text.trim(),
      category: tag.category
    };

    // Perform the API call to save the tag
    const response = await axiosInstance.post("/save-tag", tagPayload);
    
    // Log and return the response data on success
    console.log("Tag saved successfully:", response.data);
    return response.data;
  } catch (error) {
    // Log the error details to the console
    console.error("Error saving tag:", error);

    // Display an error message to the user
    message.error("Failed to save tag: " + (error.response?.data?.message || error.message));

    // Re-throw the error in case further error handling is needed elsewhere
    throw error;
  }
};

// Fetch tags function
const fetchTags = async () => {
  console.log("Starting to fetch tags...");
  try {
    console.log("Making request to /tags endpoint...");
    const response = await axiosInstance.get("/tags");
    console.log("Fetched tags response:", response);
    console.log("Tags data:", response.data);
    console.log("Tags data type:", typeof response.data);
    console.log("Is array:", Array.isArray(response.data));
    console.log("Tags data length:", response.data ? response.data.length : 0);
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error("Error fetching tags:", error);
    console.error("Error details:", error.message);
    console.error("Error response:", error.response);
    return [];
  }
};

// Adding a custom Quill module
Quill.register("modules/insertTime", insertTime);

// MainPage funtion
function MainPage() {
  const navigate = useNavigate();
  const { user, logout, navigateToUserProfile } = useContext(AuthContext);

  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().substring(0, 5);
  };

  const isFutureDate = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const checkDate = new Date(dateString);
    return checkDate > today;
  };

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const adjustedDate = new Date(
      new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000,
    );
    return adjustedDate.toLocaleDateString(undefined, options);
  };

  // Modal Dialog Processing
  const [isModalVisible, setIsModalVisible] = useState(false); // Controls modal visibility
  const [printRange, setPrintRange] = useState([null, null]); // Holds the selected date range

  // Function to show the modal when Print is clicked
  const handlePrintClick = () => {
    setIsModalVisible(true);
  };

  // Function to handle the modal submission (OK button)
  const handleModalOk = () => {
      if (printRange[0] && printRange[1]) {
        // Convert to ISO string and extract the date portion
        const startDate = new Date(printRange[0]).toISOString().split('T')[0];
        const endDate = new Date(printRange[1]).toISOString().split('T')[0];
        handlePrint(startDate, endDate);
      }
      setIsModalVisible(false);
  };

  const handlePrint = async (startDate, endDate) => {
    try {
      // Navigate to the print view with the selected date range
      navigate(`/print/${startDate}/${endDate}`);
    } catch (error) {
      console.error("Error navigating to print view:", error);
    }
  };

  // Function to close the modal without submitting
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Function to store the selected date range
  const handleRangeChange = (dates) => {
    setPrintRange(dates);
  };

  // getDefaultData Function
const getDefaultData = useCallback(() => {
    const defaultData = {
        mood: [{
            feeling: 0,
            flag: false,
            redZone: false,
            time: null,
            notes: "",
            tags: []
        }],
        medication: [{
            medication: "",
            quantity: "",
            time: null
        }],
        dietCoke: [{ time: null }],  // Initialize with one empty entry
        milk: [{ time: null }],      // Initialize with one empty entry
        exercise: {
            walkingMiles: "",
            ruckWeight: "",
            resistance: false
        },
        sleep: {
            arise: "",
            toSleep: "",
            quality: "",
            depression: 0,
            headache: 0,
            nap: false,
            napLength: "",
            notes: "" // Add notes field to sleep section
        },
        food: {
            cheatDay: false,
            notes: ""
        },
        weather: null
    };
    console.log("Generated default data:", defaultData);
    return defaultData;
}, []);

  // Tag Categories
  const [tagCategories, setTagCategories] = useState([
    { name: "Physical", color: "#1a9cd4" },
    { name: "Negative", color: "#f50" },
    { name: "Positive", color: "#87d068" },
  ]);

  // General State Variables
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [data, setData] = useState(getDefaultData());
  const [isViewNotesVisible, setIsViewNotesVisible] = useState(false);
  const [isGeneralNotesVisible, setIsGeneralNotesVisible] = useState(false);
  const [generalNotes, setGeneralNotes] = useState("");
  const [allNotes, setAllNotes] = useState([]);
  const [isNapLengthModalVisible, setIsNapLengthModalVisible] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(null);

  // Tag State Variables
  const [allTags, setAllTags] = useState([]);
  const [inputVisible, setInputVisible] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(tagCategories[0].name);

  //Medication State Variables
  const [isMedicationValuesModalVisible, setIsMedicationValuesModalVisible] =
    useState(false);
  const [medicationOptions, setMedicationOptions] = useState([
    "None",
    "Methylphenidate 18 MG ER Tablet",
    "Monjaro, 7.5mg/0.5Ml Pen",
    "Short Acting Insulin",
    "Long Acting Insulin",
    "Methylphenidate 20 MG Tablet",
    "Excedrine Migraine Relief 250mg Caplet",
    "Aleve 220mg Caplet",
    "Vitamin D",
    "Dexmethyphenidate HCL ER Cap 5 Mg",
    "Methylphenidate 5 MG Tablet",
    "Methylphenidate 10 MG Tablet",
    "Trulicity 0.75 mg/0.5 MediaList"
  ]);

  //Medication Functions
  const handleMedicationValuesModalOpen = () => {
    setIsMedicationValuesModalVisible(true);
  };

  const handleMedicationValuesModalClose = () => {
    setIsMedicationValuesModalVisible(false);
  };

  // Handle medication values save function
  const handleMedicationValuesSave = async (newMedications) => {
    try {
      console.log("[DEBUG] Saving new medications:", newMedications);
      
      // Enhanced logging for debugging
      console.log("[DEBUG] Current medication options before update:", medicationOptions);
      
      // Immediately update local state and localStorage for instant feedback
      // Create a deep copy to ensure state updates properly
      const medicationsToSave = [...newMedications];
      setMedicationOptions(medicationsToSave);
      
      // Store in localStorage for persistence across page refreshes
      localStorage.setItem('medicationOptions', JSON.stringify(medicationsToSave));
      console.log("[DEBUG] Updated localStorage with new medications");
      
      try {
        // Then send to server with explicit content type
        const response = await axiosInstance.post("/update-medication-values", {
          medications: medicationsToSave,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        console.log("[DEBUG] Server response for update:", response.status, typeof response.data);
        
        // Check if response is JSON (success) or HTML (auth redirect)
        const isJsonResponse = typeof response.data === 'object';
        
        if (response.status === 200 && isJsonResponse) {
          console.log("[DEBUG] Update successful, refetching values");
          // Refetch values from the server to ensure consistency
          await fetchMedicationValues();
          message.success("Medication list updated successfully");
        } else if (response.status === 200) {
          // HTML response but 200 status - likely auth redirect
          console.log("[DEBUG] Server returned HTML instead of JSON, using local values");
          // We'll rely on our localStorage values which are already set
          message.success("Medication list updated locally");
        }
      } catch (serverError) {
        console.error("Server error updating medication values:", serverError);
        // Even if server update fails, we've already updated localStorage and state
        message.info("Medication list saved locally, but server update failed");
      }
    } catch (error) {
      console.error("Error in medication values save function:", error);
      message.error("Failed to update medication list");
      
      // Even if function fails completely, we've already updated localStorage and state
      // so the values will persist on refresh
    }
  };

  // Handle nap checkbox change
  const handleNapChange = (checked) => {
    handleFieldChange("sleep", "nap", checked);
    if (checked) {
      setIsNapLengthModalVisible(true);
    } else {
      handleFieldChange("sleep", "napLength", "");
    }
  };

  // Handle nap length modal
  const handleNapLengthModalOk = () => {
    setIsNapLengthModalVisible(false);
  };

  const handleNapLengthModalCancel = () => {
    setIsNapLengthModalVisible(false);
    // If user cancels without entering nap length, uncheck the nap checkbox
    if (!data.sleep.napLength) {
      handleFieldChange("sleep", "nap", false);
    }
  };

  // Tag functions
const handleClose = (removedTag, moodIndex) => {
  const updatedMood = [...data.mood];
  updatedMood[moodIndex].tags = updatedMood[moodIndex].tags.filter(
    (tag) =>
      !(tag.text === removedTag.text && tag.category === removedTag.category),
  );
  
  // Update state
  setData((prevData) => ({
    ...prevData,
    mood: updatedMood,
  }));

  // Direct server call
  axiosInstance.post("/save", {
    section: 'mood',
    field: `tags${moodIndex + 1}`,
    value: JSON.stringify(updatedMood[moodIndex].tags),
    date: date
  });
};

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

// handleTagSelect function
const handleTagSelect = async (selectedTags, moodIndex) => {
  if (!selectedTags) return;  // Add guard clause
  
  try {
    // Parse selected tags only once
    const parsedTags = selectedTags.map(tagStr => {
      if (typeof tagStr === 'string') {
        try {
          return JSON.parse(tagStr);
        } catch {
          return tagStr;
        }
      }
      return tagStr;
    }).filter(tag => tag && tag.text && tag.category);

    // Single state update
    setData(prevData => {
      const updatedMood = [...prevData.mood];
      updatedMood[moodIndex] = {
        ...updatedMood[moodIndex],
        tags: parsedTags
      };
      return {
        ...prevData,
        mood: updatedMood
      };
    });

    // Server update
    await axiosInstance.post("/save", {
      section: 'mood',
      field: `tags${moodIndex + 1}`,
      value: parsedTags,
      date: date
    });

  } catch (error) {
    console.error("Error in handleTagSelect:", error);
    message.error("Failed to update tags");
  }
};

const addNewTag = async (moodIndex) => {
  if (inputValue && !allTags.some(
    (tag) => tag.text === inputValue && tag.category === selectedCategory,
  )) {
    const newTag = { text: inputValue, category: selectedCategory };

    try {
      // Save new tag to tags table
      await saveTag(newTag);
      
      // Update allTags state
      setAllTags((prevTags) => [...prevTags, newTag]);

      // Update data state and save to server
      setData((prevData) => {
        const updatedMood = [...prevData.mood];
        const currentTags = Array.isArray(updatedMood[moodIndex].tags) 
          ? updatedMood[moodIndex].tags 
          : [];
        
        const newTags = [...currentTags, newTag];
        
        updatedMood[moodIndex] = {
          ...updatedMood[moodIndex],
          tags: newTags
        };

        // Save directly to server
        axiosInstance.post("/save", {
          section: 'mood',
          field: `tags${moodIndex + 1}`,
          value: newTags,
          date: date
        });

        return {
          ...prevData,
          mood: updatedMood,
        };
      });

      setInputValue("");
    } catch (error) {
      console.error("Error saving new tag:", error);
      message.error("Failed to save new tag");
    }
  } else if (inputValue) {
    message.info("This tag already exists");
  }
};

  const updateSelectedCategory = (value) => {
    setSelectedCategory(value);
  };

  const updateAllTags = async (newTags) => {
    try {
      const existingTags = new Set(
        allTags.map((tag) =>
          JSON.stringify({ text: tag.text, category: tag.category }),
        ),
      );
      const uniqueNewTags = newTags.filter(
        (newTag) =>
          !existingTags.has(
            JSON.stringify({ text: newTag.text, category: newTag.category }),
          ),
      );

      await Promise.all(uniqueNewTags.map((tag) => saveTag(tag)));
      setAllTags((prevAllTags) => [...prevAllTags, ...uniqueNewTags]);
    } catch (error) {
      console.error("Error updating tags:", error);
    }
  };

  // Fetch data for date function
  const fetchDataForDate = async (selectedDate) => {
    console.log("Fetching data for date:", selectedDate);
    try {
      const [dataResponse, tagsResponse] = await Promise.all([
        axiosInstance.get('/data/' + selectedDate),
        axiosInstance.get('/mood-tags/' + selectedDate)
      ]);

      console.log("Data received from server:", dataResponse.data);
      console.log("Tags received from server:", tagsResponse.data);

      // Transform array data into structured object
      const transformedData = {
        mood: [],
        medication: [],
        dietCoke: [],
        milk: [],
        exercise: {},
        sleep: {},
        food: {}
      };

      if (Array.isArray(dataResponse.data)) {
        dataResponse.data.forEach(item => {
          const { section, field, value } = item;
          const normalizedSection = section.toLowerCase();
          
          if (normalizedSection === 'mood') {
            const moodIndex = parseInt(field.replace(/[^0-9]/g, ''), 10) - 1;
            const fieldName = field.replace(/[0-9]/g, '');
            
            // Initialize mood entry if it doesn't exist
            while (transformedData.mood.length <= moodIndex) {
              transformedData.mood.push({
                feeling: 0,
                flag: false,
                redZone: false,
                time: null,
                notes: "",
                tags: []
              });
            }
            
            transformedData.mood[moodIndex][fieldName] = value;
          } 
          else if (normalizedSection === 'medication') {
            const medIndex = parseInt(field.replace(/[^0-9]/g, ''), 10) - 1;
            const fieldName = field.replace(/[0-9]/g, '');
            
            while (transformedData.medication.length <= medIndex) {
              transformedData.medication.push({
                medication: "",
                quantity: "",
                time: null
              });
            }
            
            transformedData.medication[medIndex][fieldName] = value;
          }
          else if (normalizedSection === 'dietcoke' || normalizedSection === 'milk') {
            const index = parseInt(field.replace(/[^0-9]/g, ''), 10) - 1;
            const arrayKey = normalizedSection === 'dietcoke' ? 'dietCoke' : 'milk';
            
            while (transformedData[arrayKey].length <= index) {
              transformedData[arrayKey].push({ time: null });
            }
            
            transformedData[arrayKey][index].time = value;
          }
          else if (normalizedSection === 'exercise') {
            transformedData.exercise[field] = value;
          }
          else if (normalizedSection === 'food') {
            if (field === 'cheatDay') {
              transformedData.food.cheatDay = value === 'true' || value === true || value === '1' || value === 1;
            } else {
              transformedData.food[field] = value;
            }
          }
          else if (normalizedSection === 'sleep') {
            transformedData.sleep[field] = value;
          }
        });
      }

      // Process mood tags
      if (Array.isArray(tagsResponse.data) && tagsResponse.data.length > 0) {
        tagsResponse.data.forEach(tagData => {
          const moodIndex = parseInt(tagData.mood_index, 10) - 1;
          
          // Ensure mood entry exists
          while (transformedData.mood.length <= moodIndex) {
            transformedData.mood.push({
              feeling: 0,
              flag: false,
              redZone: false,
              time: null,
              notes: "",
              tags: []
            });
          }

          // Parse and set tags
          try {
            const parsedTags = tagData.tags && typeof tagData.tags === 'string' 
              ? JSON.parse(tagData.tags) 
              : tagData.tags || [];
            
            transformedData.mood[moodIndex].tags = parsedTags.filter(tag => 
              tag && tag.text && tag.category
            );
          } catch (parseError) {
            console.error(`Error parsing tags for mood index ${moodIndex}:`, parseError);
            transformedData.mood[moodIndex].tags = [];
          }
        });
      }

      console.log("Final processed data:", transformedData);
      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData(getDefaultData()); // Use default data in case of an error
    }
  };

  const getUniqueTags = (tags) => {
    // First ensure tags is an array
    if (!Array.isArray(tags)) {
      return [];
    }
    
    const seen = new Set();
    return tags.filter(tag => {
      // Ensure tag is a valid object
      if (!tag || typeof tag !== 'object') {
        return false;
      }
      
      try {
        const key = `${tag.text}-${tag.category}`;
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
        return true;
      } catch (err) {
        return false;
      }
    });
  };

  // Helper function to parse boolean values
  const parseBoolean = (value) => {
    return value === true || value === "true" || value === 1 || value === "1";
  };

  const getMoonPhase = (date) => {
    const phase = computeMoonPhase(date);
    if (phase === 0 || phase === 1) return "New Moon";
    if (phase < 0.25) return "Waxing Crescent";
    if (phase === 0.25) return "First Quarter";
    if (phase < 0.5) return "Waxing Gibbous";
    if (phase === 0.5) return "Full Moon";
    if (phase < 0.75) return "Waning Gibbous";
    if (phase === 0.75) return "Last Quarter";
    return "Waning Crescent";
  };

  const computeMoonPhase = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const c = Math.floor(365.25 * year);
    const e = Math.floor(30.6 * month);
    const jd = c + e + day - 694039.09;
    const dp = jd / 29.53;
    return dp - Math.floor(dp);
  };

    // Fetch weather data function
  const [coordinates, setCoordinates] = useState({
    latitude: 44.854031, // Default coordinates as fallback
    longitude: -93.460167,
    isLoading: true,
    error: null
  });

  const [location, setLocation] = useState('');

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          
          setCoordinates({
            latitude: lat,
            longitude: lon,
            isLoading: false,
            error: null
          });
          
          // Fetch location name
          try {
            const locationResponse = await axiosInstance.get('/api/location', {
              params: { lat, lon }
            });
            setLocation(locationResponse.data.formattedLocation);
          } catch (error) {
            console.error("Error fetching location name:", error);
            setLocation("Unknown location");
          }
          
          console.log("Location obtained:", lat, lon);
        },
        (error) => {
          console.error("Error getting location:", error.message);
          setCoordinates(prev => ({
            ...prev,
            isLoading: false,
            error: `Location error: ${error.message}. Using default location.`
          }));
          // Keep using default coordinates in this case
          const fetchDefaultLocation = async () => {
            try {
              const locationResponse = await axiosInstance.get('/api/location', {
                params: { 
                  lat: 44.854031, // Default coordinates
                  lon: -93.460167 
                }
              });
              setLocation(locationResponse.data.formattedLocation);
            } catch (error) {
              console.error("Error fetching default location name:", error);
              setLocation("Unknown location");
            }
          };
          fetchDefaultLocation();
        },
        { 
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0
        }
      );
    } else {
      setCoordinates(prev => ({
        ...prev,
        isLoading: false,
        error: "Geolocation is not supported by this browser. Using default location."
      }));
      console.error("Geolocation not supported");
      const fetchDefaultLocation = async () => {
        try {
          const locationResponse = await axiosInstance.get('/api/location', {
            params: { 
              lat: 44.854031, // Default coordinates
              lon: -93.460167 
            }
          });
          setLocation(locationResponse.data.formattedLocation);
        } catch (error) {
          console.error("Error fetching default location name:", error);
          setLocation("Unknown location");
        }
      };
      fetchDefaultLocation();
    }
  }, []);

  const fetchWeatherData = async (selectedDate) => {
    try {
      // First check if there's already weather data with location for this date
      let savedLocation = null;
      try {
        const dateDataResponse = await axiosInstance.get(`/data/${selectedDate}`);
        if (dateDataResponse.data && 
            dateDataResponse.data.weather && 
            dateDataResponse.data.weather.location) {
          savedLocation = dateDataResponse.data.weather.location;
          console.log("Found saved location for date:", savedLocation);
        }
      } catch (error) {
        console.error("Error checking for existing location data:", error);
      }
      
      // Fetch current weather data
      const weatherResponse = await axiosInstance.get('/api/weather', {
        params: { 
          lat: coordinates.latitude, 
          lon: coordinates.longitude,
          date: selectedDate
        },
      });

      // Add moon phase and location to the weather data
      const weatherDataWithLocation = {
        ...weatherResponse.data,
        moonPhase: getMoonPhase(new Date(selectedDate)),
        location: savedLocation || location // Use saved location if available, otherwise use current
      };

      // Save the location data to the database
      try {
        await axiosInstance.post('/save', {
          section: 'weather',
          field: 'location',
          value: weatherDataWithLocation.location,
          date: selectedDate
        });
        console.log("Location data saved to database:", weatherDataWithLocation.location);
      } catch (saveError) {
        console.error("Error saving location data:", saveError);
      }

      // Save the moon phase data to the database
      try {
        await axiosInstance.post('/save', {
          section: 'weather',
          field: 'moonPhase',
          value: weatherDataWithLocation.moonPhase,
          date: selectedDate
        });
        console.log("Moon phase data saved to database");
      } catch (saveError) {
        console.error("Error saving moon phase data:", saveError);
      }

      return weatherDataWithLocation;
    } catch (error) {
      console.error("Error fetching weather data:", error);
      return null;
    }
  };

    // Fetch all notes function
    const fetchAllNotes = useCallback(async () => {
      try {
        const response = await axiosInstance.get("/all-general-notes");
        if (Array.isArray(response.data)) {
          setAllNotes(response.data);
        } else {
          console.error('Unexpected notes data format:', response.data);
          setAllNotes([]);
        }
      } catch (error) {
        console.error('Error fetching notes:', error);
        message.error('Failed to fetch notes');
        setAllNotes([]);
      }
    }, []);

  useEffect(() => {
    fetchAllNotes();
  }, []);  

useEffect(() => {
  const loadTags = async () => {
    console.log("Loading tags in useEffect...");
    try {
      const tags = await fetchTags();
      console.log("Tags loaded in useEffect:", tags);
      setAllTags(tags);
    } catch (error) {
      console.error("Error loading tags:", error);
    }
  };
  loadTags();
}, []);

// Right before the useEffect
/* console.log("About to set up fetchDataForDate effect", {
  hasFetchDataForDate: !!fetchDataForDate,
  hasFetchWeatherData: !!fetchWeatherData,
  hasIsFutureDate: !!isFutureDate,
  hasSendToServer: !!sendToServer,
  hasGetDefaultData: !!getDefaultData
}); */

  // Fetch medication values effect
  // Create a dedicated function to fetch medication values
  const fetchMedicationValues = async () => {
    console.log("[DEBUG] Starting fetchMedicationValues function");
    console.log("[DEBUG] Current medicationOptions state:", medicationOptions);
    
    try {
      console.log("[DEBUG] Fetching medication values from server");
      const response = await axiosInstance.get("/medication-values");
      console.log("[DEBUG] Server response for medication values:", response.data);
      
      if (response.data && Array.isArray(response.data)) {
        console.log("[DEBUG] Setting medication options from server:", response.data);
        // Store the data in a variable first to verify it's being set correctly
        const newMedicationOptions = [...response.data]; // Create a new array to ensure state change
        
        // Enhanced logging for debugging
        console.log("[DEBUG] New medication options to be set:", newMedicationOptions);
        
        // Set state with the new medication options
        setMedicationOptions(newMedicationOptions);
        
        // Update localStorage with the latest data from server
        localStorage.setItem('medicationOptions', JSON.stringify(newMedicationOptions));
        console.log("[DEBUG] Saved medication options to localStorage");
        
        // Return the fetched data for potential use by caller
        return newMedicationOptions;
      } else {
        console.log("[DEBUG] Invalid server response, checking localStorage");
        // Fallback to localStorage if API returns invalid data
        const storedOptions = localStorage.getItem('medicationOptions');
        
        if (storedOptions) {
          try {
            const parsedOptions = JSON.parse(storedOptions);
            if (Array.isArray(parsedOptions)) {
              // Enhanced logging
              console.log("[DEBUG] Using medication options from localStorage:", parsedOptions);
              
              setMedicationOptions([...parsedOptions]); // Create a new array to ensure state change
              console.log("[DEBUG] Set medication options from localStorage");
              
              // Return the localStorage data
              return parsedOptions;
            }
          } catch (parseError) {
            console.error("Error parsing stored medication options:", parseError);
            setMedicationOptions([]); // Set to empty array if parsing fails
          }
        } else {
          console.log("[DEBUG] No medication options found in localStorage");
          setMedicationOptions([]); // Set to empty array if no stored options
        }
        
        // Return empty array if no valid data found
        return [];
      }
    } catch (error) {
      console.error("Error fetching medication values:", error);
      console.log("[DEBUG] Error fetching from server, checking localStorage");
      
      // Fallback to localStorage if API fails
      const storedOptions = localStorage.getItem('medicationOptions');
      
      if (storedOptions) {
        try {
          const parsedOptions = JSON.parse(storedOptions);
          if (Array.isArray(parsedOptions)) {
            setMedicationOptions([...parsedOptions]); // Create a new array to ensure state change
            console.log("[DEBUG] Set medication options from localStorage (after error)");
          }
        } catch (parseError) {
          console.error("Error parsing stored medication options:", parseError);
          setMedicationOptions([]); // Set to empty array if parsing fails
        }
      } else {
        console.log("[DEBUG] No medication options found in localStorage (after error)");
        setMedicationOptions([]); // Set to empty array if no stored options
      }
    }
  };

  // Call fetchMedicationValues on component mount
  useEffect(() => {
    // Fetch medication values on component mount
    const initializeMedicationValues = async () => {
      console.log("[DEBUG] Initializing medication values on component mount");
      
      // First check localStorage for any cached values
      const storedOptions = localStorage.getItem('medicationOptions');
      let initialOptions = [];
      
      if (storedOptions) {
        try {
          const parsedOptions = JSON.parse(storedOptions);
          if (Array.isArray(parsedOptions) && parsedOptions.length > 0) {
            console.log("[DEBUG] Found medication options in localStorage:", parsedOptions);
            initialOptions = parsedOptions;
            // Set initial state from localStorage for immediate display
            setMedicationOptions([...initialOptions]);
          }
        } catch (error) {
          console.error("Error parsing stored medication options:", error);
        }
      }
      
      // Then fetch from server to ensure we have the latest data
      await fetchMedicationValues();
    };
    
    initializeMedicationValues();
  }, []);
  
  // Monitor changes to medicationOptions
  useEffect(() => {
    console.log("[DEBUG] medicationOptions changed:", medicationOptions);
    console.log("[DEBUG] localStorage medicationOptions:", localStorage.getItem('medicationOptions'));
  }, [medicationOptions]);

  // Handle save general notes function
  const saveGeneralNotes = async () => {
    console.log('=== DEBUG: saveGeneralNotes called ===');
    console.log('generalNotes:', generalNotes);
    console.log('date:', date);
    
    if (!generalNotes.trim()) {
      message.warning('Please enter some notes before saving');
      return;
    }

    try {
      console.log('=== DEBUG: Making API request ===');
      const response = await axiosInstance.post('/save-general-notes', {
        date,
        notes: generalNotes,
      });
      console.log('=== DEBUG: API response ===', response.data);
      
      // First fetch the updated notes
      console.log('=== DEBUG: Fetching updated notes ===');
      await fetchAllNotes();
      console.log('=== DEBUG: Notes fetched ===');
      
      // Then show success and reset UI state
      message.success('Notes saved successfully');
      setGeneralNotes('');
      setIsGeneralNotesVisible(false);
    } catch (error) {
      console.error('=== DEBUG: Error saving notes ===', error);
      message.error('Failed to save notes');
    }
  };

/*   const formatTime = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }; */

/*   const calculateDaylight = (sunrise, sunset) => {
    const daylightSeconds = sunset - sunrise;
    const hours = Math.floor(daylightSeconds / 3600);
    const minutes = Math.floor((daylightSeconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }; */

  // Handler for the notes icon click to ensure the field is empty when opened
  const handleNotesIconClick = () => {
    setGeneralNotes(""); // Ensure the field is empty
    setIsGeneralNotesVisible(true);
  };

  const handleViewNotesClick = () => {
  setIsViewNotesVisible(true); // Show the ViewNotesModal
};

  // Send data to server function
  const sendToServer = useCallback(
    async (section, field, value) => {
      const formattedDate = date;
      let payload;

      if (section === "mood" && field.startsWith("tags")) {
        const tagsValue = Array.isArray(value) ? value : [];
        const formattedTags = tagsValue.map(tag => ({
          text: tag.text || "",
          category: tag.category || "Default"
        }));
        
        const [, index] = field.match(/tags(\d+)/);
        payload = {
          section,
          field: `tags${index}`,
          value: formattedTags,
          date: formattedDate,
          moodIndex: index
        };
      } else {
        // Ensure consistent section name format for database storage
        // The server expects section names with first letter capitalized
        const normalizedSection = section.toLowerCase();
        
        payload = { 
          section, 
          field, 
          value: value === undefined ? null : value, 
          date: formattedDate 
        };
        
        // Add extra logging for medication-related data
        if (normalizedSection === 'medication') {
          console.log(`[DEBUG] Sending medication data to server:`, payload);
        }
      }

      console.log("Formatted payload being sent to server:", payload);

      return axiosInstance
        .post("/save", payload)
        .then((response) => {
          console.log("Data saved successfully:", response.data);
          return response;
        })
        .catch((error) => {
          console.error("Error saving data:", error);
          if (error.response) {
            console.error("Error response data:", error.response.data);
            console.error("Error response status:", error.response.status);
            console.error("Error response headers:", error.response.headers);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error message:", error.message);
          }
          throw error;
        });
    },
    [date]
  );

  // handleFieldChange function
  const handleFieldChange = useCallback(
    (section, field, value) => {
      console.log("handleFieldChange called with:", { section, field, value });
      
      // Ensure consistent section name format
      const normalizedSection = section.toLowerCase();
      
      // Add specific logging for medication changes
      if (normalizedSection === 'medication') {
        console.log(`[DEBUG] Updating medication field: ${field} with value:`, value);
      }
      
      setData((prevData) => {
        // Always create deep copies of array sections to ensure state updates properly
        const newData = { 
          ...prevData,  // Preserve all sections
          mood: [...prevData.mood],  // Deep copy arrays
          medication: [...prevData.medication],
          dietCoke: [...prevData.dietCoke],
          milk: [...prevData.milk]
        };
        
        if (normalizedSection === 'medication') {
          console.log(`[DEBUG] Current medication state before update:`, JSON.stringify(newData.medication));
        }

        if (normalizedSection === 'sleep') {
          newData.sleep = {
            ...prevData.sleep,  // Preserve all sleep fields
            [field]: value
          };
        } else if (normalizedSection === 'food') {
          newData.food = {
            ...newData.food,
            [field]: value
          };
        } else if (normalizedSection === 'exercise') {
          newData.exercise = {
            ...newData.exercise,
            [field]: value
          };
        } else if (normalizedSection === 'mood') {
          // Handle mood entries
          const match = field.match(/(\w+)(\d+)/);
          if (match) {
            const [, fieldName, index] = match;
            const moodIndex = parseInt(index) - 1;
            
            while (newData.mood.length <= moodIndex) {
              newData.mood.push({
                feeling: 0,
                flag: false,
                redZone: false,
                time: null,
                notes: "",
                tags: []
              });
            }
            
            newData.mood[moodIndex] = {
              ...newData.mood[moodIndex],
              [fieldName]: value
            };
          }
        } else if (normalizedSection === 'medication') {
          // Handle medication entries
          const match = field.match(/(\w+)(\d+)/);
          if (match) {
            const [, fieldName, index] = match;
            const medIndex = parseInt(index) - 1;
            
            console.log(`[DEBUG] Updating medication field: ${fieldName} at index ${medIndex} with value:`, value);
            
            while (newData.medication.length <= medIndex) {
              newData.medication.push({
                medication: "",
                quantity: "",
                time: null
              });
            }
            
            newData.medication[medIndex] = {
              ...newData.medication[medIndex],
              [fieldName]: value
            };
            
            console.log(`[DEBUG] Updated medication array:`, JSON.stringify(newData.medication));
          }
        } else if (normalizedSection === 'dietcoke' || normalizedSection === 'milk') {
          const arrayKey = normalizedSection === 'dietcoke' ? 'dietCoke' : 'milk';
          const match = field.match(/time(\d+)/);
          if (match) {
            const index = parseInt(match[1]) - 1;
            while (newData[arrayKey].length <= index) {
              newData[arrayKey].push({ time: null });
            }
            newData[arrayKey][index].time = value;
          }
        }

        return newData;
      });

      // Send to server
      sendToServer(section, field, value);
    },
    [sendToServer]
  );

  // handleAddEntry Function
  const handleAddEntry = useCallback((section) => {
    setData((prevData) => {
      let newData = { ...prevData };
      let newEntry;
      if (section === "dietCoke" || section === "milk") {
        if (!Array.isArray(newData[section])) {
          newData[section] = [];
        }
        newEntry = { time: getCurrentTime() };
        newData[section] = [...newData[section], newEntry];

        // Save the new entry to the server
        const index = newData[section].length;
        sendToServer(section, `time${index}`, newEntry.time);
      } else if (section === "mood") {
        newEntry = {
          feeling: 0,
          flag: false,
          redZone: false,
          time: getCurrentTime(),
          notes: "",
        };
        newData.mood = [...newData.mood, newEntry];

        // Save the new entry to the server
        const index = newData.mood.length;
        Object.entries(newEntry).forEach(([key, value]) => {
          sendToServer(section, `${key}${index}`, value);
        });
      } else if (section === "medication") {
        newEntry = { medication: "", quantity: "", time: null };
        newData.medication = [...newData.medication, newEntry];

        // Save the new entry to the server
        const index = newData.medication.length;
        Object.entries(newEntry).forEach(([key, value]) => {
          sendToServer(section, `${key}${index}`, value);
        });
      }

      return newData;
    });
  }, []);

  // handleRemoveEntry Function
  const handleRemoveEntry = useCallback((section, index) => {
    setData((prevData) => {
      const newData = { ...prevData };
      if (newData[section].length > 1) {
        newData[section].splice(index, 1);
      }
      return newData;
    });

    const fieldsToDelete = [];
    if (section === "mood") {
      fieldsToDelete.push(
        `feeling${index + 1}`,
        `flag${index + 1}`,
        `redZone${index + 1}`,
        `time${index + 1}`,
        `notes${index + 1}`,
      );
    } else if (section === "dietCoke" || section === "milk") {
      fieldsToDelete.push(`time${index + 1}`);
    } else if (section === "medication") {
      fieldsToDelete.push(
        `medication${index + 1}`,
        `quantity${index + 1}`,
        `time${index + 1}`,
      );
    }

    const deleteRequests = fieldsToDelete.map((field) =>
      axiosInstance.post("/delete", {
        section,
        field,
        date,
      }),
    );

    Promise.all(deleteRequests)
      .then(() => {
        console.log(`Successfully deleted ${section} entry at index ${index}`);
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  }, []);

  // handleDateChange Function
  const handleDateChange = (direction) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + direction);
    setDate(currentDate.toISOString().split("T")[0]);
  };

  // Fetch weekly metrics summary data function
const fetchWeeklyMetricsSummaryData = async (dateRange) => {
  const [startDate, endDate] = dateRange;
  const formattedStartDate = startDate.format('YYYY-MM-DD');
  const formattedEndDate = endDate.format('YYYY-MM-DD');

  try {
    const response = await axiosInstance.get(
      `/weekly-metrics-summary/${formattedStartDate}/${formattedEndDate}`
    );
    const summaryData = response.data;
    console.log("Summary data received:", summaryData);

    setWeeklySummaryData(summaryData);
    setDateRange(`${startDate.format('YYYY-MM-DD')} - ${endDate.format('YYYY-MM-DD')}`);
    setIsWeeklySummaryVisible(true);
  } catch (error) {
    console.error("Error fetching weekly metrics summary data:", error);
    console.error("Error details:", error.response?.data);
  }
};

  // State variable for weekly metrics summary page
  const [isWeeklySummaryVisible, setIsWeeklySummaryVisible] = useState(false);
  const [weeklySummaryData, setWeeklySummaryData] = useState(null);
  const [dateRange, setDateRange] = useState("");

/*   const averageTime = (times) => {
    const validTimes = times.filter((time) => time);
    if (validTimes.length === 0) return "N/A";
    const totalMinutes = validTimes.reduce((sum, time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return sum + hours * 60 + minutes;
    }, 0);
    const averageMinutes = Math.round(totalMinutes / validTimes.length);
    const hours = Math.floor(averageMinutes / 60);
    const minutes = averageMinutes % 60;
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${period}`;
  }; */

  // generatePDF Function
  const generatePDF = (data) => {
    const pdf = new jsPDF("p", "mm", "a4");
    let yOffset = 10;
    let totalPages = Object.keys(data).length; // Get total number of pages

    // Helper function to parse HTML content
    const parseHtmlContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const parseNode = (node, indent = 0, listType = null) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent.trim();
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        switch (node.tagName.toLowerCase()) {
          case "ul":
            listType = "ul";
            return Array.from(node.childNodes)
              .map((child) => parseNode(child, indent, listType))
              .join("\n");
          case "ol":
            listType = "ol";
            return Array.from(node.childNodes)
              .map((child, index) =>
                parseNode(child, indent, listType, index + 1),
              )
              .join("\n");
          case "li":
            const bullet = listType === "ul" ? "•" : `${arguments[3]}.`;
            const content = Array.from(node.childNodes)
              .map((child) => parseNode(child, indent + 1))
              .join(" ")
              .trim();
            const indentString = " ".repeat(indent * 4);
            const bulletString = `${indentString}${bullet} `;
            const contentLines = pdf.splitTextToSize(
              content,
              180 - (indent + 1) * 4,
            );
            return (
              bulletString +
              contentLines.join(`\n${" ".repeat(bulletString.length)}`)
            );
          default:
            return Array.from(node.childNodes)
              .map((child) => parseNode(child, indent))
              .join(" ");
        }
      }
      return "";
    };

    const content = parseNode(doc.body);
    return content.split("\n");
  };

    // Helper function to format date
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    };

    // Helper function to add bold text
    const addBoldText = (text, x, y) => {
      pdf.setFont("helvetica", "bold");
      pdf.text(text, x, y);
      pdf.setFont("helvetica", "normal");
    };

    // Helper function to add page number
    const addPageNumber = (pageNum) => {
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "normal");
      const text = `Page ${pageNum} of ${totalPages}`;
      const textWidth = pdf.getTextWidth(text);
      const x = (pdf.internal.pageSize.width - textWidth) / 2;
      pdf.text(text, x, 287); // 287 mm is near the bottom of an A4 page
    };

    Object.entries(data).forEach(([dateString, dayData], pageIndex) => {
      if (pageIndex > 0) {
        pdf.addPage();
        yOffset = 10;
      }

      // Add page number at the beginning of each page
      addPageNumber(pageIndex + 1);
      yOffset = 10;

      // Add formatted date in bold
      const formattedDate = formatDate(dateString);
      pdf.setFontSize(16);
      addBoldText(formattedDate, 10, yOffset);
      yOffset += 10;

      // Function to add a section with bold headings and labels
      const addSection = (title, content) => {
        pdf.setFontSize(14);
        addBoldText(title, 10, yOffset);
        yOffset += 7;
        pdf.setFontSize(10);
        content.forEach((line) => {
          if (typeof line === "string") {
            const [label, ...rest] = line.split(":");
            if (rest.length > 0) {
              const value = rest.join(":").trim();
              addBoldText(label + ":", 15, yOffset);
              pdf.text(
                ": " + value,
                15 + pdf.getTextWidth(label + ": "),
                yOffset,
              );
            } else {
              pdf.text(line, 15, yOffset);
            }
            yOffset += 5;
          } else if (Array.isArray(line)) {
            line.forEach((subline) => {
              pdf.text(subline, 15, yOffset);
              yOffset += 5;
            });
          }
          if (yOffset > 280) {
            pdf.addPage();
            yOffset = 10;
            addPageNumber(pageIndex + 2);
          }
        });
        yOffset += 5;
      };

      // Add Weather section
      if (dayData.weather) {
        addSection("Weather", [
          `Temperature: ${dayData.weather.temperature || "N/A"}°F`,
          `Weather: ${dayData.weather.description || "N/A"}`,
          `Sunrise: ${dayData.weather.sunrise || "N/A"}`,
          `Sunset: ${dayData.weather.sunset || "N/A"}`,
          `Hours of Daylight: ${dayData.weather.hoursOfDaylight || "N/A"}`,
          `Location: ${dayData.weather.location || "N/A"}`,
        ]);
      }

      // Add Sleep section
      if (dayData.Sleep) {
        addSection("Sleep", [
          `Arise: ${dayData.Sleep.arise || "Not set"}`,
          `Sleep Quality: ${dayData.Sleep.sleepQuality || "N/A"}`,
          `Depression: ${dayData.Sleep.depression || "N/A"}`,
          `Headache: ${dayData.Sleep.headache || "N/A"}`,
          `Bedtime: ${dayData.Sleep.bedtime || "Not set"}`,
          `Notes:`,
          ...parseHtmlContent(dayData.Sleep.notes || "None"),
        ]);
      }

      // Add Diet Coke section
      if (dayData.dietCoke) {
        const dietCokeEntries = Object.entries(dayData.dietCoke).map(
          ([key, value]) => `${key}: ${value}`,
        );
        addSection(
          "Diet Coke",
          dietCokeEntries.length > 0
            ? dietCokeEntries
            : ["No Diet Coke entries"],
        );
      }

      // Add Milk section
      if (dayData.milk) {
        const milkEntries = Object.entries(dayData.milk).map(
          ([key, value]) => `${key}: ${value}`,
        );
        addSection(
          "Milk",
          milkEntries.length > 0 ? milkEntries : ["No Milk entries"],
        );
      }

      // Add Food section
      if (dayData.food) {
        addSection("Food", [
          `Cheat Day: ${dayData.food.cheatDay ? "Yes" : "No"}`,
          ...parseHtmlContent(dayData.food.foodConsumed || "No food recorded"),
        ]);
      }

      // Add Mood section
      if (dayData.mood) {
        Object.entries(dayData.mood).forEach(([key, value], index) => {
          if (key.startsWith("feeling")) {
            const moodIndex = key.replace("feeling", "");
            addSection(`Mood ${moodIndex}`, [
              `Feeling: ${value || "N/A"}`,
              `Time: ${dayData.mood[`time${moodIndex}`] || "N/A"}`,
              `Notes:`,
              parseHtmlContent(dayData.mood[`notes${moodIndex}`] || "None"),
            ]);
          }
        });
      }

      // Add Exercise section
      if (dayData.exercise) {
        addSection("Exercise", [
          `Walking Miles: ${dayData.exercise.walkingMiles || "N/A"}`,
          `Ruck Weight (lbs): ${dayData.exercise.ruckWeight || "N/A"}`,
          `Resistance?: ${dayData.exercise.resistance ? "Yes" : "No"}`,
        ]);
      }

      // Add Medication section
      if (dayData.medication) {
        Object.entries(dayData.medication).forEach(([key, value], index) => {
          if (key.startsWith("medication")) {
            const medIndex = key.replace("medication", "");
            addSection(`Medication ${medIndex}`, [
              `Medication: ${value || "Not specified"}`,
              `Qty: ${dayData.medication[`quantity${medIndex}`] || "Not specified"}`,
              `Time: ${dayData.medication[`time${medIndex}`] || "Not specified"}`,
            ]);
          }
        });
      }
    });

    pdf.save("health_tracker_report.pdf");
  };

  const modulesWithTime = {
    toolbar: {
      container: [
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
        [{ insertTime: "Time" }],
      ],
      handlers: {
        insertTime: insertTime,
      },
    },
  };

  const modulesWithoutTime = {
    toolbar: {
      container: [
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
      ],
    },
  };

  // handleViewData Function
  const handleViewData = () => {
    navigate("/view-data"); // Add navigation to ViewData component
  };

  // handleViewGraph Function
  const handleViewGraph = () => {
    navigate("/view-graph"); // Navigate to the View Graph page
  };

// In fetchDataForDateWithWeather callback
const fetchDataForDateWithWeather = useCallback(async () => {
  console.log("Starting fetchDataForDateWithWeather for date:", date);
  try {
    const [dataResponse, tagsResponse, weatherResponse] = await Promise.all([
      axiosInstance.get(`/data/${date}`),
      axiosInstance.get(`/mood-tags/${date}`),
      !isFutureDate(date) ? axiosInstance.get(`/api/weather?lat=${coordinates.latitude}&lon=${coordinates.longitude}&date=${date}`) : Promise.resolve(null)
    ]);

    console.log("Data Response:", dataResponse.data);
    console.log("Tags Response:", tagsResponse.data);
    console.log("Weather Response:", weatherResponse?.data);

    // Create a deep copy of the default data structure
    const defaultData = getDefaultData();
    let dataToSet = {
      ...defaultData,
      mood: [{  // Always initialize with one empty entry
        feeling: 0,
        flag: false,
        redZone: false,
        time: null,
        notes: "",
        tags: []
      }],
      medication: [{  // Always initialize with one empty entry
        medication: "",
        quantity: "",
        time: null
      }],
      dietCoke: [{ time: null }],
      milk: [{ time: null }],
      exercise: {
        walkingMiles: "",
        ruckWeight: "",
        resistance: false
      },
      sleep: {
        arise: "",
        toSleep: "",
        quality: "",
        nap: false,
        napLength: "",
        notes: ""
      },
      food: {
        cheatDay: false,
        notes: ""
      },
      weather: null
    };

    if (dataResponse.data) {
      console.log('[DEBUG] Processing data from server:', dataResponse.data);
      // Handle each section separately since we know the exact structure
      Object.entries(dataResponse.data).forEach(([section, items]) => {
        // Ensure case-insensitive section name matching
        const normalizedSection = section.toLowerCase();
        
        // Add specific logging for medication data
        if (normalizedSection === 'medication') {
          console.log('[DEBUG] Processing medication section from server:', items);
        }
        
        // Handle weather separately as it's a special case
        if (normalizedSection === 'weather') {
          dataToSet.weather = items;
          return;
        }
        
        // For all other sections, items is always an array
        items.forEach(item => {
          const { field, value } = item;
          
          console.log(`[DEBUG] Processing item for section: ${normalizedSection}, field: ${field}, value:`, value);
          
          if (normalizedSection === 'mood') {
            const moodIndex = parseInt(field.replace(/[^0-9]/g, ''), 10) - 1;
            const fieldName = field.replace(/[0-9]/g, '');
            
            while (dataToSet.mood.length <= moodIndex) {
              dataToSet.mood.push({
                feeling: 0,
                flag: false,
                redZone: false,
                time: null,
                notes: "",
                tags: []
              });
            }
            
            dataToSet.mood[moodIndex][fieldName] = value;
          } 
          // Handle medication section (case-insensitive matching)
          else if (normalizedSection === 'medication') {
            const medIndex = parseInt(field.replace(/[^0-9]/g, ''), 10) - 1;
            const fieldName = field.replace(/[0-9]/g, '');
            
            console.log(`[DEBUG] Processing medication field: ${fieldName} at index ${medIndex} with value:`, value);
            
            while (dataToSet.medication.length <= medIndex) {
              dataToSet.medication.push({
                medication: "",
                quantity: "",
                time: null
              });
            }
            
            dataToSet.medication[medIndex][fieldName] = value;
            console.log(`[DEBUG] Updated medication array:`, JSON.stringify(dataToSet.medication));
          }
          else if (normalizedSection === 'dietcoke' || normalizedSection === 'milk') {
            const index = parseInt(field.replace(/[^0-9]/g, ''), 10) - 1;
            const arrayKey = normalizedSection === 'dietcoke' ? 'dietCoke' : 'milk';
            
            if (field.startsWith('time')) {
              while (dataToSet[arrayKey].length <= index) {
                dataToSet[arrayKey].push({ time: null });
              }
              dataToSet[arrayKey][index].time = value;
            }
          }
          else if (normalizedSection === 'exercise') {
            dataToSet.exercise[field] = value;
          }
          else if (normalizedSection === 'food') {
            if (field === 'cheatDay') {
              dataToSet.food.cheatDay = value === 'true' || value === true || value === '1' || value === 1;
            } else {
              dataToSet.food[field] = value;
            }
          }
          else if (normalizedSection === 'sleep') {
            if (field === 'nap') {
              dataToSet.sleep.nap = value === 'true' || value === true || value === '1' || value === 1;
            } else {
              dataToSet.sleep[field] = value;
            }
          }
        });
      });
    }

    // Process weather data if available
    if (weatherResponse?.data) {
      dataToSet.weather = weatherResponse.data;
    }

    // Process mood tags if available
    if (Array.isArray(tagsResponse.data) && tagsResponse.data.length > 0) {
      console.log("Mood tags data received:", tagsResponse.data);
      tagsResponse.data.forEach(tagData => {
        const moodIndex = parseInt(tagData.mood_index, 10) - 1;
        console.log("Processing mood index:", moodIndex, "with tags:", tagData.tags);
        
        // Ensure mood entry exists
        while (dataToSet.mood.length <= moodIndex) {
          dataToSet.mood.push({
            feeling: 0,
            flag: false,
            redZone: false,
            time: null,
            notes: "",
            tags: []
          });
        }

        // Parse and set tags
        try {
          const parsedTags = tagData.tags && typeof tagData.tags === 'string' 
            ? JSON.parse(tagData.tags) 
            : tagData.tags || [];
          
          console.log("Parsed tags:", parsedTags);
          
          dataToSet.mood[moodIndex].tags = parsedTags.filter(tag => 
            tag && tag.text && tag.category
          );
          
          console.log("Final tags for mood index", moodIndex, ":", dataToSet.mood[moodIndex].tags);
        } catch (parseError) {
          console.error(`Error parsing tags for mood index ${moodIndex}:`, parseError);
          dataToSet.mood[moodIndex].tags = [];
        }
      });
    } else {
      console.log("No mood tags data received or empty array:", tagsResponse.data);
    }

    console.log("Final processed data:", dataToSet);
    setData(dataToSet);

  } catch (error) {
    console.error("Comprehensive error in fetchDataForDateWithWeather:", {
      message: error.message,
      stack: error.stack,
      responseData: error.response?.data
    });
    
    setData(getDefaultData());
  }
}, [date, coordinates.latitude, coordinates.longitude, location]);

  useEffect(() => {
    fetchDataForDateWithWeather();
  }, [fetchDataForDateWithWeather]);

  // Function to force refresh tags
  const refreshTags = async () => {
    try {
      console.log("Force refreshing tags...");
      const tags = await fetchTags();
      console.log("Tags refreshed:", tags);
      setAllTags(tags);
      message.success("Tags refreshed successfully");
    } catch (error) {
      console.error("Error refreshing tags:", error);
      message.error("Failed to refresh tags");
    }
  };

  // User menu items
  const userMenuItems = [
    {
      key: 'profile',
      label: 'Profile',
      icon: React.createElement(UserOutlined),
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: React.createElement(LogoutOutlined),
      danger: true,
    },
  ];
  
  // Handle user menu click
  const handleUserMenuClick = ({ key }) => {
    if (key === 'logout') {
      logout();
      navigate('/login');
    } else if (key === 'profile') {
      navigateToUserProfile();
    }
  };

  // return
  return (
    <div className="container">
      {/* Header Section */}
      <div className="header-wrapper">
        <header>
          <LeftCircleOutlined
            onClick={() => handleDateChange(-1)}
            className="date-change-icon"
          />
          <div className="date-container">
            <div className="date-display">{formatDate(date)}</div>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <RightCircleOutlined
            onClick={() => handleDateChange(1)}
            className="date-change-icon"
          />
        </header>
      </div>

      {/* Weather Section */}
      <section>
        <div className="section-header">
          <h2>Weather{data.weather && data.weather.location ? <span style={{ fontSize: '0.8em', fontWeight: 'normal' }}> - {data.weather.location}</span> : ''}</h2>
        </div>
        {coordinates.error && (
          <div className="location-error" style={{ color: 'orange', marginBottom: '10px', fontSize: '14px' }}>
            {coordinates.error}
          </div>
        )}
        {coordinates.isLoading ? (
          <Spin size="small" /> 
        ) : (
          <>
            {console.log("Weather data being passed to WeatherInfo:", data.weather)}
            <WeatherInfo weatherData={data.weather} />
          </>
        )}
      </section>

      {/* Sleep Section */}
      <section>
        <div className="section-header">
          <h2>Sleep</h2>
        </div>
        <div className="sleep-row">
          <div className="form-group">
            <label><strong>Arise</strong></label>
            <input
              type="time"
              value={data.sleep?.arise || ""}
              onChange={(e) =>
                handleFieldChange("sleep", "arise", e.target.value)
              }
              step="900"
            />
          </div>
          <div className="form-group">
            <label><strong>To Sleep</strong></label>
            <input
              type="time"
              value={data.sleep?.toSleep || ""}
              onChange={(e) =>
                handleFieldChange("sleep", "toSleep", e.target.value)
              }
              step="900"
            />
          </div>
          <div className="form-group">
            <label><strong>Sleep Quality</strong></label>
            <input
              type="number"
              step="0.5"
              min="0"
              max="5"
              value={data.sleep?.quality || ""}
              onChange={(e) =>
                handleFieldChange(
                  "sleep",
                  "quality",
                  e.target.value
                )
              }
            />
          </div>
          <div className="form-group">
            <label><strong>Depression</strong></label>
            <input
              type="number"
              step="0.5"
              min="0"
              max="5"
              value={data.sleep?.depression || 0}
              onChange={(e) =>
                handleFieldChange("sleep", "depression", e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label><strong>Headache</strong></label>
            <input
              type="number"
              step="0.5"
              min="0"
              max="5"
              value={data.sleep?.headache || 0}
              onChange={(e) =>
                handleFieldChange("sleep", "headache", e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label><strong>Nap</strong></label>
            <input
              type="checkbox"
              checked={data.sleep?.nap || false}
              onChange={(e) => handleNapChange(e.target.checked)}
            />
          </div>
        </div>
        <ReactQuill
          className="sleep-quill"
          value={data.sleep?.notes || ""}
          onChange={(value) =>
            handleFieldChange("sleep", "notes", value)
          }
          modules={modulesWithoutTime}
        />
      </section>

      {/* Food Section */}
      <section>
        <div className="section-header">
          <h2>Food</h2>
          <div className="cheat-day-checkbox">
            <label>
              <strong>Cheat Day</strong>
              <input
                type="checkbox"
                checked={data.food?.cheatDay || false}
                onChange={(e) =>
                  handleFieldChange("food", "cheatDay", e.target.checked)
                }
              />
            </label>
          </div>
        </div>
        <ReactQuill
          className="food-quill"
          value={data.food?.notes || ""}
          onChange={(value) =>
            handleFieldChange("food", "notes", value)
          }
          modules={modulesWithTime}
        />
      </section>

      {/* Beverages Section */}
      <section>
        <div className="section-header">
          <h2>Beverages</h2>
        </div>
        <div className="beverages-container">
          {/* Diet Coke Column */}
          <div className="beverage-column">
            <h3>Diet Coke</h3>
            {data.dietCoke.map((entry, index) => (
              <div key={index} className="beverage-row">
                <div className="form-group">
                  <label><strong>Diet Coke {index + 1}</strong></label>
                  <input
                    type="time"
                    value={entry.time || ""}
                    onChange={(e) =>
                      handleFieldChange("dietCoke", `time${index + 1}`, e.target.value)
                    }
                    step="900"
                  />
                </div>
                <div className="beverage-buttons">
                  <button 
                    onClick={() => handleAddEntry("dietCoke")}
                    className="add-beverage-btn"
                  >
                    +
                  </button>
                  <button 
                    onClick={() => index > 0 && handleRemoveEntry("dietCoke", index)}
                    className={`remove-beverage-btn ${index === 0 ? 'disabled' : ''}`}
                    disabled={index === 0}
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Milk Column */}
          <div className="beverage-column">
            <h3>Milk</h3>
            {data.milk.map((entry, index) => (
              <div key={index} className="beverage-row">
                <div className="form-group">
                  <label><strong>Milk {index + 1}</strong></label>
                  <input
                    type="time"
                    value={entry.time || ""}
                    onChange={(e) =>
                      handleFieldChange("milk", `time${index + 1}`, e.target.value)
                    }
                    step="900"
                  />
                </div>
                <div className="beverage-buttons">
                  <button 
                    onClick={() => handleAddEntry("milk")}
                    className="add-beverage-btn"
                  >
                    +
                  </button>
                  <button 
                    onClick={() => index > 0 && handleRemoveEntry("milk", index)}
                    className={`remove-beverage-btn ${index === 0 ? 'disabled' : ''}`}
                    disabled={index === 0}
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Mood Section */}
      <section>
        <div className="section-header">
          <h2>Mood</h2>
        </div>
        {(data.mood || []).map((entry, index) => (
          <div className="mood-entry" key={index}>
            <div className="mood-row">
              <div className="form-group">
                <label><strong>Mood {index + 1}</strong></label>
                <input
                  type="number"
                  step="0.5"
                  min="0"
                  max="5"
                  value={entry.feeling || 0}
                  onChange={(e) =>
                    handleFieldChange(
                      "mood",
                      `feeling${index + 1}`,
                      parseFloat(e.target.value) || 0,
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label><strong>Time</strong></label>
                <input
                  type="time"
                  value={entry.time || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      "mood",
                      `time${index + 1}`,
                      e.target.value,
                    )
                  }
                  step="900"
                />
              </div>
              <div className="form-group-flag">
                <label><strong>Flag:</strong></label>
                <input
                  type="checkbox"
                  checked={!!entry.flag}
                  onChange={(e) =>
                    handleFieldChange(
                      "mood",
                      `flag${index + 1}`,
                      e.target.checked,
                    )
                  }
                />
              </div>
              <div className="form-group-red-zone">
                <label><strong>Red Zone:</strong></label>
                <input
                  type="checkbox"
                  checked={!!entry.redZone}
                  onChange={(e) =>
                    handleFieldChange(
                      "mood",
                      `redZone${index + 1}`,
                      e.target.checked,
                    )
                  }
                />
              </div>
              <div className="mood-buttons">
                <button onClick={() => handleAddEntry("mood")}>+</button>
                <button
                  onClick={() => handleRemoveEntry("mood", index)}
                  disabled={index === 0 || data.mood.length === 1}
                >
                  -
                </button>
              </div>
            </div>
            <div
              className="mood-tags"
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              {/* Display selected tags above the dropdown */}
              <div className="selected-tags" style={{ marginBottom: "10px" }}>
                {(Array.isArray(entry?.tags) ? entry.tags : []).map((tag, tagIndex) => {
                  const category = tagCategories.find(cat => cat.name === tag?.category);
                  return (
                    <Tag
                      key={tagIndex}
                      color={category?.color || '#1a9cd4'} // Default to blue if category not found
                      closable
                      onClose={() => handleClose(tag, index)}
                      style={{ marginRight: "5px", marginBottom: "5px" }}
                    >
                      {tag?.text || ''}
                    </Tag>
                  );
                })}
              </div>

              {/* Tag selection dropdown */}

          <Select
            mode="multiple"
            style={{ width: "100%", marginBottom: "10px" }}
            placeholder="Select or create tags"
            value={Array.isArray(entry?.tags) ? entry.tags.map(tag => {
              try {
                return JSON.stringify(tag);
              } catch (e) {
                console.error('Error stringifying tag:', e);
                return '';
              }
            }).filter(Boolean) : []}
            maxTagCount={0}
            open={inputVisible[index]}
            onDropdownVisibleChange={(visible) => {
              setInputVisible((prev) => ({
                ...prev,
                [index]: visible,
              }));
            }}
            onChange={(selectedTags) => handleTagSelect(selectedTags, index)}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    padding: 8,
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <Input
                    style={{ flex: "auto", marginRight: "8px" }}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="New tag"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        addNewTag(index);
                      }
                    }}
                  />
                  <Select
                    style={{ width: 120, marginRight: "8px" }}
                    value={selectedCategory}
                    onChange={updateSelectedCategory}
                    onClick={(e) => e.stopPropagation()}
                    dropdownStyle={{ zIndex: 1100 }}
                  >
                    {(tagCategories || []).map((category) => (
                      <Option key={category.name} value={category.name}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    type="primary"
                    onClick={() => addNewTag(index)}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}
          >
          {Array.isArray(allTags) && allTags.length > 0 ? 
          allTags.map((tag) => {
            if (!tag || typeof tag !== 'object') return null;

            const category = tagCategories.find(cat => cat.name === tag.category);
            
            try {
              const optionKey = JSON.stringify(tag);
              const optionValue = JSON.stringify(tag);

              return (
                <Option
                  key={optionKey}
                  value={optionValue}
                  label={tag.text || ''}
                >
                  <Tag
                    color={category?.color || '#1a9cd4'} // Default to blue if category not found
                  >
                    {tag.text || ''}
                  </Tag>
                </Option>
              );
            } catch (err) {
              console.error('Failed to stringify tag:', err);
              return null;
            }
          }).filter(Boolean) 
          : null
        }
          </Select>
            </div>
            <div className="form-group">
              <label><strong>Notes</strong></label>
              <ReactQuill
                value={entry.notes || ""}
                onChange={(value) =>
                  handleFieldChange("mood", `notes${index + 1}`, value)
                }
                modules={modulesWithoutTime}
              />
            </div>
          </div>
        ))}
      </section>

      {/* Exercise Section */}
      <section>
        <div className="section-header">
          <h2>Exercise</h2>
        </div>
        <div className="exercise-row">
          <div className="form-group">
            <label><strong>Walking Miles</strong></label>
            <input
              type="number"
              step="0.5"
              min="0"
              max="5"
              value={data.exercise.walkingMiles || 0}
              onChange={(e) =>
                handleFieldChange(
                  "exercise",
                  "walkingMiles",
                  parseFloat(e.target.value),
                )
              }
            />
          </div>
          <div className="form-group">
            <label><strong>Ruck Weight (lbs)</strong></label>
            <input
              type="number"
              step="5"
              min="0"
              max="30"
              value={data.exercise.ruckWeight || 0}
              onChange={(e) =>
                handleFieldChange(
                  "exercise",
                  "ruckWeight",
                  parseInt(e.target.value),
                )
              }
            />
          </div>
          <div className="form-group">
            <label><strong>Resistance?</strong></label>
            <input
              type="checkbox"
              checked={!!data.exercise.resistance} // Ensure it's a boolean
              onChange={(e) =>
                handleFieldChange("exercise", "resistance", e.target.checked)
              }
            />
            {console.log(
              "Rendering checkbox with state:",
              !!data.exercise.resistance,
            )}
          </div>
        </div>
      </section>

      {/* Medication Section */}
      <section>
        <div className="section-header">
          <h2>Medication</h2>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={handleMedicationValuesModalOpen}
            style={{ color: '#1a9cd4' }}
          />
        </div>
        {data.medication.map((entry, index) => (
          <div
            className={`medication-row ${index % 2 === 1 ? "alternate-row" : ""}`}
            key={index}
          >
            <div className="form-group medication-select">
              <label><strong>Medication {index + 1}</strong></label>
              <select
                value={entry.medication || ""}
                onChange={(e) =>
                  handleFieldChange(
                    "medication",
                    `medication${index + 1}`,
                    e.target.value,
                  )
                }
              >
                <option value="">Select Medication</option>
                {medicationOptions.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label><strong>Qty</strong></label>
              <input
                type="number"
                min="0.25"
                step="0.25"
                value={entry.quantity}
                onChange={(e) =>
                  handleFieldChange(
                    "medication",
                    `quantity${index + 1}`,
                    e.target.value,
                  )
                }
              />
            </div>

            <div className="form-group">
              <label><strong>Time</strong></label>
              <input
                type="time"
                value={entry.time || ""}
                onChange={(e) =>
                  handleFieldChange(
                    "medication",
                    `time${index + 1}`,
                    e.target.value,
                  )
                }
                step="900"
              />
            </div>

            <div className="medication-buttons">
              <button onClick={() => handleAddEntry("medication")}>+</button>
              <button
                onClick={() => handleRemoveEntry("medication", index)}
                disabled={index === 0 || data.medication.length === 1}
              >
                -
              </button>
            </div>
          </div>
        ))}

        <MedicationValuesModal
          isVisible={isMedicationValuesModalVisible}
          onClose={handleMedicationValuesModalClose}
          onSave={handleMedicationValuesSave}
          currentMedications={medicationOptions}
        />
      </section>

      {/* Footer Section */}
      <footer className="data-footer">
        <div className="button-container">
          <div className="notes-icon-wrapper" onClick={handleNotesIconClick}>
            <div className="icon-label-container">
              <FileTextOutlined
                style={{ fontSize: "24px", color: "#1a9cd4" }}
              />
              <span className="icon-label">New Note</span>
            </div>
          </div>
          <div className="notes-icon-wrapper" onClick={handleViewNotesClick}>
          <div className="icon-label-container">
            <EyeOutlined style={{ fontSize: "24px", color: "#1a9cd4" }} />
            <span className="icon-label">View Notes</span>
          </div>
        </div>
          <div className="notes-icon-wrapper" onClick={handleViewGraph}>
            <div className="icon-label-container">
              <AreaChartOutlined
                style={{ fontSize: "24px", color: "#1a9cd4" }}
              />
              <span className="icon-label">View Graph</span>
            </div>
          </div>
          <div className="notes-icon-wrapper" onClick={handleViewData}>
            <div className="icon-label-container">
              <DatabaseOutlined
                style={{ fontSize: "24px", color: "#1a9cd4" }}
              />
              <span className="icon-label">View Data</span>
            </div>
          </div>
          <div className="notes-icon-wrapper" onClick={handlePrintClick}>
            <div className="icon-label-container">
              <PrinterOutlined style={{ fontSize: "24px", color: "#1a9cd4" }} />
              <span className="icon-label">Print</span>
            </div>
          </div>
          <div className="notes-icon-wrapper" onClick={() => {
            const startOfWeek = dayjs(date).startOf('week');
            const endOfWeek = dayjs(date).endOf('week');
            fetchWeeklyMetricsSummaryData([startOfWeek, endOfWeek]);
          }}>
            <div className="icon-label-container">
              <BarChartOutlined
                style={{ fontSize: "24px", color: "#1a9cd4" }}
              />
              <span className="icon-label">Metrics Summary</span>
            </div>
          </div>
          <div className="notes-icon-wrapper" onClick={refreshTags}>
            <div className="icon-label-container">
              <EditOutlined
                style={{ fontSize: "24px", color: "#1a9cd4" }}
              />
              <span className="icon-label">Refresh Tags</span>
            </div>
          </div>
          <div className="notes-icon-wrapper">
            <Dropdown
              menu={{ items: userMenuItems, onClick: handleUserMenuClick }}
              placement="bottomRight"
              arrow
            >
              <div className="icon-label-container">
                <UserOutlined style={{ fontSize: "24px", color: "#1a9cd4" }} />
                <span className="icon-label">User</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </footer>

      {/* Nap Length Modal */}
      <Modal
        title="Enter Nap Length"
        open={isNapLengthModalVisible}
        onOk={handleNapLengthModalOk}
        onCancel={handleNapLengthModalCancel}
      >
        <div className="form-group">
          <label><strong>Nap Length (hours)</strong></label>
          <input
            type="number"
            step="0.25"
            min="0"
            max="24"
            value={data.sleep?.napLength || ""}
            onChange={(e) => handleFieldChange("sleep", "napLength", e.target.value)}
            style={{ width: "100%", marginTop: "8px" }}
          />
        </div>
      </Modal>

      {/* Print Range Modal */}
      <Modal
        title="Select Print Date Range"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Print"
        cancelText="Cancel"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <RangePicker onChange={handleRangeChange} />
      </Modal>

      {/* Weekly Metrics Summary Modal */}
      <WeeklyMetricsSummaryModal
        isVisible={isWeeklySummaryVisible}
        onClose={() => setIsWeeklySummaryVisible(false)}
        summaryData={weeklySummaryData}
        dateRange={dateRange}
        fetchData={fetchWeeklyMetricsSummaryData}
      />

      {/* General Notes Modal */}
      <GeneralNotesModal
        isVisible={isGeneralNotesVisible}
        onClose={() => setIsGeneralNotesVisible(false)}
        onSave={saveGeneralNotes}
        value={generalNotes}
        onChange={setGeneralNotes}
      />

      {/* View General Notes Modal */}
      <ViewNotesModal
        isVisible={isViewNotesVisible}
        onClose={() => setIsViewNotesVisible(false)}
        notes={allNotes}
        onNotesUpdated={fetchAllNotes}
      />
    </div>
  );
}

export default MainPage;
