// ./api/axiosConfig.js
import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';

const axiosInstance = axios.create({
  // In development, we use localhost:5003 as the base URL
  // In production, we don't set a baseURL since endpoints are proxied through Apache
  // When using different endpoints, ensure each one has proper proxy rules in Apache
  baseURL: isDevelopment ? 'http://localhost:5003' : '',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 30000, // 30 second timeout
});

// Function to set auth token
export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Add environment check and logging
axiosInstance.interceptors.request.use(
  (config) => {
    if (isDevelopment) {
      console.log(`Request: ${config.method.toUpperCase()} ${config.baseURL}${config.url}`);
    }
    return config;
  },
  (error) => {
    if (isDevelopment) {
      console.error('Request Error:', error);
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (isDevelopment) {
      console.log(`Response: ${response.status} ${response.config.url}`);
    }
    return response;
  },
  (error) => {
    // Enhanced error logging that works in both development and production
    // This provides better visibility while preserving existing functionality
    if (error.response) {
      // Always log API errors regardless of environment
      console.error(
        `API Error: ${error.response.status} ${error.config.url}`, 
        error.response.data
      );
      
      // Handle specific status codes if needed
      switch (error.response.status) {
        case 401:
          // Handle unauthorized - redirect to login
          window.location.href = '/login';
          break;
        case 403:
          // Handle forbidden
          break;
        case 404:
          // Handle not found
          break;
        case 500:
          // Handle server error
          break;
        default:
          break;
      }
    } else if (error.request) {
      // Request was made but no response received
      // Always log network errors regardless of environment
      console.error('Network Error - No response received:', {
        url: error.config?.url,
        method: error.config?.method,
        requestTime: new Date().toISOString()
      });
    } else {
      // Error in request configuration
      // Always log configuration errors regardless of environment
      console.error('Configuration Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;