import React, { useState, useEffect, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "./api/axiosConfig";
import { Spin, Alert, Button, Progress } from "antd";
import "./styles.css";

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("PrintView error caught:", error, errorInfo);
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="print-view error-container">
          <Alert
            message="Error Rendering Print View"
            description={this.state.error?.message || "An unexpected error occurred"}
            type="error"
            showIcon
          />
          <div style={{ marginTop: 16 }}>
            <Button type="primary" onClick={() => window.location.href = "/"}>
              Return to Main Page
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Day Data Component - Renders a single day's data
const DayData = React.memo(({ date, dayData, formatDate }) => {
  const renderArrayOrSingle = (items, renderItem) => {
    if (Array.isArray(items)) {
      return items.map((item, index) => renderItem(item, index));
    } else if (items && typeof items === 'object') {
      return renderItem(items, 0);
    }
    return null;
  };

  return (
    <div key={date} className="day-data">
      <h2>{formatDate(date)}</h2>

      {(dayData.weather || dayData.Weather) && (
        <div className="section">
          <h3>Weather</h3>
          <p>Temperature: {dayData.weather?.temperature || dayData.Weather?.temperature}</p>
          <p>Weather: {dayData.weather?.description || dayData.Weather?.description}</p>
          <p>Sunrise: {dayData.weather?.sunrise || dayData.Weather?.sunrise}</p>
          <p>Sunset: {dayData.weather?.sunset || dayData.Weather?.sunset}</p>
          <p>Hours of Daylight: {dayData.weather?.hoursOfDaylight || dayData.Weather?.hoursOfDaylight}</p>
        </div>
      )}

      {(dayData.sleep || dayData.Sleep) && (
        <div className="section">
          <h3>Sleep</h3>
          <p>Arise: {dayData.sleep?.arise || dayData.Sleep?.arise}</p>
          <p>Sleep Quality: {dayData.sleep?.sleepQuality || dayData.Sleep?.sleepQuality}</p>
          <p>Depression: {dayData.sleep?.depression || dayData.Sleep?.depression}</p>
          <p>Headache: {dayData.sleep?.headache || dayData.Sleep?.headache}</p>
          <p>Bedtime: {dayData.sleep?.bedtime || dayData.Sleep?.bedtime}</p>
          <p>Notes: {dayData.sleep?.notes || dayData.Sleep?.notes}</p>
        </div>
      )}

      {/* Check for both lowercase and capitalized section names */}
      {(dayData.dietcoke || dayData.dietCoke) && (
        <div className="section">
          <h3>Diet Coke</h3>
          {renderArrayOrSingle(dayData.dietcoke || dayData.dietCoke, (entry, index) => (
            <p key={index}>
              #{index + 1}: {entry.time}
            </p>
          ))}
        </div>
      )}

      {/* Check for both lowercase and capitalized section names */}
      {(dayData.milk || dayData.Milk) && (
        <div className="section">
          <h3>Milk</h3>
          {renderArrayOrSingle(dayData.milk || dayData.Milk, (entry, index) => (
            <p key={index}>
              #{index + 1}: {entry.time}
            </p>
          ))}
        </div>
      )}

      {(dayData.food || dayData.Food) && (
        <div className="section">
          <h3>Food</h3>
          {typeof (dayData.food || dayData.Food) === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: dayData.food || dayData.Food }} />
          ) : (
            <p>Cheat Day: {(dayData.food && dayData.food.cheatDay) || (dayData.Food && dayData.Food.cheatDay) ? "Yes" : "No"}</p>
          )}
        </div>
      )}

      {(dayData.mood || dayData.Mood) && (
        <div className="section">
          <h3>Mood</h3>
          {renderArrayOrSingle((dayData.mood || dayData.Mood), (entry, index) => (
            <div key={index}>
              <p>
                Mood {index + 1}: {entry.feeling}
              </p>
              <p>Time: {entry.time}</p>
              <p>Notes: {entry.notes}</p>
            </div>
          ))}
        </div>
      )}

      {(dayData.exercise || dayData.Exercise) && (
        <div className="section">
          <h3>Exercise</h3>
          <p>Walking Miles: {dayData.exercise?.walkingMiles || dayData.Exercise?.walkingMiles}</p>
          <p>Ruck Weight (lbs): {dayData.exercise?.ruckWeight || dayData.Exercise?.ruckWeight}</p>
          <p>Resistance?: {dayData.exercise?.resistance || dayData.Exercise?.resistance ? "Yes" : "No"}</p>
        </div>
      )}

      {(dayData.medication || dayData.Medication) && (
        <div className="section">
          <h3>Medication</h3>
          {renderArrayOrSingle((dayData.medication || dayData.Medication), (entry, index) => (
            <div key={index}>
              <p>
                Medication {index + 1}: {entry.medication}
              </p>
              <p>Qty: {entry.quantity}</p>
              <p>Time: {entry.time}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

const PrintView = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [renderedDates, setRenderedDates] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const { startDate, endDate } = useParams();
  const navigate = useNavigate();

  // Format date for display
  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    try {
      return new Date(dateString).toLocaleDateString(undefined, options);
    } catch (e) {
      console.error("Error formatting date:", e);
      return dateString;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`Fetching print data for date range: ${startDate} to ${endDate}`);
        setLoading(true);
        setError(null);
        setLoadingProgress(10);
        
        // Validate date format
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
          throw new Error("Invalid date format. Use YYYY-MM-DD.");
        }
        
        setLoadingProgress(30);
        console.log("Making request to /print-data endpoint...");
        const response = await axiosInstance.get(`/print-data/${startDate}/${endDate}`);
        console.log("Received print data:", response.data);
        console.log("Response status:", response.status);
        console.log("First date in response:", Object.keys(response.data)[0]);
        if (Object.keys(response.data).length > 0) {
          const firstDate = Object.keys(response.data)[0];
          console.log("Sections for first date:", Object.keys(response.data[firstDate]));
        }
        setLoadingProgress(60);
        
        if (!response.data || Object.keys(response.data).length === 0) {
          setError("No data available for the selected date range.");
        } else {
          setData(response.data);
          
          // Load data in chunks to prevent browser freezing
          const allDates = Object.keys(response.data).sort();
          
          // First chunk - load first 3 dates immediately
          const firstChunk = allDates.slice(0, 3);
          setRenderedDates(firstChunk);
          setLoadingProgress(80);
          
          // Load remaining dates in chunks
          if (allDates.length > 3) {
            setTimeout(() => {
              setRenderedDates(allDates);
              setLoadingProgress(100);
            }, 500);
          } else {
            setLoadingProgress(100);
          }
        }
      } catch (error) {
        console.error("Error fetching print data:", error);
        setError(error.response?.data?.error || error.message || "Failed to fetch print data");
        setLoadingProgress(100);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const handlePrint = () => {
    window.print();
  };

  const handleBack = () => {
    navigate("/");
  };

  if (loading) {
    return (
      <div className="print-view loading-container">
        <Spin size="large" tip="Loading print data..." />
        <Progress percent={loadingProgress} status="active" style={{ marginTop: 20, width: '80%' }} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="print-view error-container">
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
        />
        <Button type="primary" onClick={handleBack} style={{ marginTop: 16 }}>
          Back to Main Page
        </Button>
      </div>
    );
  }

  if (Object.keys(data).length === 0) {
    return (
      <div className="print-view no-data-container">
        <Alert
          message="No Data"
          description="No data available for the selected date range."
          type="info"
          showIcon
        />
        <Button type="primary" onClick={handleBack} style={{ marginTop: 16 }}>
          Back to Main Page
        </Button>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="print-view">
        <div className="print-header">
          <h1>Health Data: {startDate} to {endDate}</h1>
          <div className="print-actions">
            <Button type="primary" onClick={handlePrint} icon={<span role="img" aria-label="print">🖨️</span>}>
              Print This Page
            </Button>
            <Button onClick={handleBack} style={{ marginLeft: 8 }}>
              Back to Main Page
            </Button>
          </div>
        </div>
        
        {/* Only render dates that have been loaded into renderedDates state */}
        {renderedDates.map(date => (
          <Suspense 
            key={date} 
            fallback={<div className="day-data-loading">Loading data for {formatDate(date)}...</div>}
          >
            <DayData 
              date={date} 
              dayData={data[date]} 
              formatDate={formatDate} 
            />
          </Suspense>
        ))}
        
        {renderedDates.length < Object.keys(data).length && (
          <div className="loading-more-dates">
            <Spin size="small" /> Loading more dates...
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default PrintView;